import React, { Component } from "react";
import User from "Models/User";
import Category from "Models/Category";
import Quickblox from "Models/Quickblox";
import ScheduleCall from "Models/ScheduleCall";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import socket from "Helpers/Socket";
import General from "Helpers/General";
import Product from "Models/Product";
import Message from "../Components/Messages/Message";
import MessagesComponent from "../Components/Messages/MessagesComponent";
import DatePicker from "react-date-picker";
import QB, {
  incomingCallRingtone,
  playEndCallAudio,
  stopCallAudio,
} from "Helpers/Quickblox";
import {
  SetQuickblox,
  CloseMessageBox,
  SetOutgoingCallState,
  SetScheduleMeetingState,
  SetIncomingCallState,
  SetQuickbloxSession,
  SetContactList,
  SetQuickbloxUnreadMessage,
} from "../Helpers/Actions";
import Modal from "react-modal";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
var qs = require("qs");
let baseUrl = process.env.REACT_APP_ApiBaseUrl;
const axios = require("axios");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class Header extends Component {
  constructor(props) {
    super(props);

    const keyword = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).keyword;

    this.state = {
      Categories: localStorage.getItem("Categories")
        ? JSON.parse(localStorage.getItem("Categories"))
        : [],
      email: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData")).email
        : "",
      firstName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData")).firstName
        : "",
      lastName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData")).lastName
        : "",
      Alerts: [],
      unreadAlerts: [],
      searchOptionDisplay: false,
      displayStatus: "Show",
      searchLocation: "",
      searchKeyword: "",
      searchCategoryId: "all",
      profilePicture: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["profilePicture"]
        : "",
      address: "",
      lat: "",
      lng: "",

      audio: true,
      video: true,
      calleeName: "",
      unreadCount: 0,
      extension: {},
      homeSearchFilterData: [],
      keyword: keyword,
      timerInterval: null,
      userNotAnswered: false,
      scheduleMeeting: false,
      scheduleDate: "",
      scheduleTime: "",
      scheduleEndTime: "",
      formattedScheduledDate: "",
      formattedScheduledTime: "",
      reviewScheduledMeeting: false,
      fromUserId: "",
      fromQbUserId: "",
      toUserId: "",
      toQbUserId: "",
      example: true,
      callNotPicked: false,
      username: "",
      customCalendarClass: "shipModal",
      fromUserName: "",
      headerLogo: "",
      footerLogo: "",
      mobileLogo: "",
      toUserEmail: "",
      toUsername: "",
      profileMenuShow: false,
      filterClassUpdated: false,
      // minutes: 15, // Initial call duration in minutes
      // seconds: 0,  // Initial call duration in seconds
    };
    this.updateFilterClass = this.updateFilterClass.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  componentDidMount = () => {
    if (Product.isLogin()) {
      socket.emit("getAllAlerts", { userId: Product.getAuthUserId() });
      socket.on("getAllAlertsResponse", (data) => {
        this.setState({ Alerts: data });
        let unreadAlert = data.filter((f) => f.read === false);
        this.setState({ unreadAlerts: unreadAlert });
      });
      socket.emit("login", { userId: Product.getAuthUserId() });
    }

    this.getUserByEmail();

    var self = this;
    Category.getApi(
      "APIS_ALL_CATEGORIES_DATA",
      async (response) => {
        localStorage.setItem("Categories", JSON.stringify(response));
        self.setState({ Categories: response });
      },
      false
    );
    Category.getApi(
      "APIS_GET_ALL_CATEGORY_FIELDS",
      async (response) => {
        localStorage.setItem("CategoriesFieldData", JSON.stringify(response));
      },
      false
    );
    Category.getApi(
      "APIS_ALL_MOTOR_CATEGORIES",
      async (response) => {
        localStorage.setItem("MotorCategories", JSON.stringify(response));
      },
      false
    );
    Category.getApi(
      "APIS_ALL_SUB_CATEGORIES",
      async (response) => {
        localStorage.setItem(
          "PostsubCategories",
          JSON.stringify(response?.catData)
        );
        localStorage.setItem(
          "subCategoriesProductCount",
          JSON.stringify(response?.productCounts)
        );
        localStorage.setItem(
          "thirdLevelCategoriesProductCount",
          JSON.stringify(response?.thirdCategoryProductCount)
        );
        self.setState({ PostsubCategories: response });
      },
      { params: { searchText: self.state.keyword } },
      false
    );
    User.getApi(
      "APIS_Get_logo",
      async (response) => {
        //  console.log("response",response.headerLogo);
        let headerLogo = `${baseUrl}image/logo/${response.headerLogo}`;
        let footerLogo = `${baseUrl}image/logo/${response.footerLogo}`;
        let mobileLogo = `${baseUrl}image/logo/${response.mobileLogo}`;
        this.setState({ headerLogo: headerLogo });
        this.setState({ footerLogo: footerLogo });
        this.setState({ mobileLogo: mobileLogo });
        localStorage.setItem(
          "HeaderLogo", headerLogo
        );
        localStorage.setItem(
          "FooterLogo", footerLogo
        );
        localStorage.setItem(
          "MobileLogo", mobileLogo
        )
      }
    )
    User.getApi(
      "APIS_GET_PRODUCT_SAFETY",
      async (response) => {
        if (response && response[0]["value"]) {
          localStorage.setItem(
            "ProductSafetyTips",
            JSON.stringify(response[0]["value"])
          );
        }
      },
      false
    );

    User.getApi(
      "APIS_GET_SECURITY_SETTING",
      async (response) => {
        if (response[0] && response[0]["captcha_setting"][0]) {
          localStorage.setItem(
            "CaptchaSetting",
            JSON.stringify(response[0]["captcha_setting"][0]["captcha"])
          );
        }
      },
      false
    );

    User.getApi(
      "APIS_GET_LIVESTREAM_SETTING",
      async (response) => {
        if (response[0] && response[0]["common_setting"][0]) {
          localStorage.setItem(
            "LiveStreamingCategorySetting",
            JSON.stringify(
              response[0]["common_setting"][0]["liveStreamingCategory"]
            )
          );

          localStorage.setItem(
            "LiveStreamingRegisterBidAmount",
            JSON.stringify(
              response[0]["common_setting"][0]["registerBidAmount"]
            )
          );
        }
      },
      false
    );
    this.props.CloseMessageBox(true);
    localStorage.setItem("isClosedMessageBox", JSON.stringify(true));
    this.configureQuickblox();

    // document.addEventListener('click', this.handleClickOutside, true);

    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.addEventListener('click', this.handleOverlayClick);
    }
  };

  handleOverlayClick() {
    document.body.classList.remove('filter-open');
    this.setState({ profileMenuShow: false });
  }

  componentWillUnmount() {
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.removeEventListener('click', this.handleOverlayClick);
    }
  }

  updateFilterClass(event) {
    console.log('updateFilterClass called...');
    event.preventDefault(); // Optional; remove if unnecessary
    this.setState(
      (prevState) => ({
        filterClassUpdated: !prevState.filterClassUpdated,
        profileMenuShow: !prevState.profileMenuShow,
      }),
      () => {
        console.log('State after update:', this.state.filterClassUpdated, this.state.profileMenuShow);
        // Dynamically toggle the class on the body
        if (this.state.profileMenuShow) {
          document.body.classList.add('filter-open');
        } else {
          document.body.classList.remove('filter-open');
        }
      }
    );
  }

  // Quickblox Code::Start
  configureQuickblox = () => {
    const { email, firstName, lastName } = this.state;
    if (email) {
      var args = { login: email, password: "password" };
      Quickblox.postApi(
        "API_QUICKBLOX_GET_USER_LOGIN", { login_id: email },
        async (response) => {
          if (response) {
            if (response.user.id) {
              console.log('Response:', response.data);
              this.LoginQBUser({
                login: email,
                password: "password",
              });
            }
          } else {
            console.log('error:', response);
            let fullName = firstName;
            if (lastName) {
              fullName += " " + lastName;
            }
            args.full_name = fullName;
            args.email = email;
            await this.CreateQBUser(args); // calls function create QB user and login account.
          }
          console.log("response-node", response);
        },
        true
      );
    }
  };

  // Login User
  createUser(data) {
    return new Promise((resolve, reject) => {
      Quickblox.postApi(
        "API_QUICKBLOX_CREATE_USER",
        data,
        (response, status) => {
          if (status === "success") {
            resolve(response);
          } else {
            reject(response);
          }
        },
        true
      );
    });
  }

  CreateQBUser = (data) => {
    this.createUser(data)
      .then(response => {
        // if(response['data'][''])
        console.log("Response:", response);
        console.log("Nested Response:", response.user);
        if (response.user.id) {
          this.LoginQBUser({
            login: data.login,
            password: data.password,
          });
        } else {
          console.log("Something is wrong");
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  LoginQBUser = (data) => {
    console.log("data", data);
    QB.createSession(data, (error, result) => {
      if (error) {
        console.log('error', error);
        console.log("Session error: Can`t login to QB");
      } else {
        QB.login(data, (error, result) => {
          // callback function
          if (!error) {
            localStorage.setItem("QB_USER", JSON.stringify(result));
            // console.log("user id", result)
            this.connectToChatServer({ userId: result.id, password: "password" }, data);
            // update User Avatar
            const custom_data = JSON.stringify({
              profilePicture: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData")).profilePicture
                : null,
            });
            QB.users.update(result.id, { custom_data }, function (err, user) { });
          } else {
            console.log("Login Error");
          }
        });
      }
    });
  };

  // Connect to chat server
  connectToChatServer = (data, reqData) => {
    QB.createSession({ 'login': reqData.login, 'password': reqData.password }, (error, result) => {
      if (error) {
        console.log('error', error);
        console.log("Session error: Can`t login to QB");
      } else {
        QB.chat.connect(data, (error, contactList) => {
          if (!error) {
            this.props.SetQuickblox(true);
            //User.setReduxStore({connection: true});
            //Storage.setLocalData("QBDATA", JSON.stringify(response));
            this.countermsg();
            this.getUsersList();
            this.getFriendList();
            this.onIncomingCall();
          } else {
            console.log("QB Connection Error");
          }
        });
      }
    });

  };

  countermsg = () => {
    QB.chat.dialog.list({ limit: 1 }, (error, dialogs) => {
      if (!error) {
        let dialog = dialogs.items[0];
        let unreadCount = dialog?.unread_messages_count;
        this.props.SetQuickbloxUnreadMessage(unreadCount);
        this.setState({ unreadCount: unreadCount });
      }
    });
  }

  getUsersList = (ids = []) => {
    QB.users.listUsers(
      {
        page: 1,
        per_page: 200,
      },
      (err, result) => {
        if (!err) {

          if (localStorage.getItem("QB_USER")) {
            const LoggedInUser = JSON.parse(localStorage.getItem("QB_USER"));

            let contacts = [];
            let tempArr = [];
            for (let userObj of result.items) {
              if (LoggedInUser.id !== userObj.user.id && !tempArr.includes(userObj.user.id)) {
                contacts.push(userObj);
                tempArr.push(userObj.user.id);
              }
            }

            const FriendList = contacts.filter(
              (contact) => ids.includes(contact.user.id)
            );

            localStorage.setItem("QB_CONTACT_LIST", JSON.stringify(FriendList));
            // this.props.SetContactList(FriendList);
          }
        } else {
          console.log(err);
        }
      }
    );
  };



  onIncomingCall = () => {
    QB.webrtc.onCallListener = (session, extension) => {
      console.log("exten onIncoming", session, extension)
      this.setState({ extension: extension });
      incomingCallRingtone(true);
      let mediaParams = {
        audio: true,
        video: session.callType === 2 ? false : { min: 320, ideal: 720, max: 1024 },
        options: {
          muted: true,
          mirror: (session.callType === 1) ? true : false,
        },
        elemId: "localVideoElem",
      };
      // if you are going to take a call
      this.props.SetQuickbloxSession(session);
      this.setState({ calleeName: extension.callee });
      session.getUserMedia(mediaParams, (error, stream) => {
        if (error) {
          incomingCallRingtone(false);
          console.log("error", error);
        } else {
          //run accept function here
          if (session) {
            this.props.SetIncomingCallState(true);
          }
        }
      });
    };

    QB.webrtc.onAcceptCallListener = (session, userId, extension) => {

      console.log("session onaccept call listener", session, extension)
      session.accept(extension);
      stopCallAudio(true);

      // const { minutes, seconds } = this.state;
      // const totalTime = minutes * 60 + seconds;
      // // Start the timer
      // this.setState({ timerInterval: setInterval(()=>this.updateTimer(), 1000) });

      // setTimeout(() => {
      //   this.endCallHandler();
      // }, totalTime * 1000);

      //alert('accepted by user');

    };
    QB.webrtc.onRemoteStreamListener = (session, userID, remoteStream) => {
      console.log("session", session)
      this.props.SetIncomingCallState(false);
      this.props.SetOutgoingCallState(false);
      incomingCallRingtone(false);
      session.attachMediaStream("remoteVideoElem", remoteStream);

      // const { minutes, seconds } = this.state;
      // const totalTime = minutes * 60 + seconds;
      // // Start the timer


      // this.setState({ timerInterval: setInterval(()=>this.updateTimer(), 1000) });

      // //clearInterval(interval)

      // setTimeout(() => {
      //   this.endCallHandler();
      // }, totalTime * 1000);

      this.addClass();
    };



    QB.webrtc.onUserNotAnswerListener = async (session, userId) => {
      console.log('user_is_not_answering_your_call', session.initiatorID
        , userId, session);
      stopCallAudio(true);
      session.stop({});
      this.props.SetOutgoingCallState(false);
      this.props.SetIncomingCallState(false);
      this.props.SetQuickbloxSession(null);
      incomingCallRingtone(false);
      playEndCallAudio();


      const selectedUser = JSON.parse(
        localStorage.getItem("QB_CONTACT_LIST")
      ).filter((u) => u.user.id === userId);

      if (selectedUser && selectedUser.length > 0) {

        fetch(process.env.REACT_APP_ApiBaseUrl + `getSelectedUser`, {
          method: "POST",
          body: JSON.stringify({
            email: selectedUser[0].user.login,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then(async (response) => {
            if (response && response !== undefined) {
              let data = await response.json();
              console.log("data", data);
              if (data && data.length) {
                console.log("userid", data[0]._id)
                this.setState({ userNotAnswered: true, fromQbUserId: session.initiatorID, toQbUserId: userId, toUserId: data[0]._id, callNotPicked: true, username: data[0].firstName + " " + data[0].lastName, toUserEmail: data[0].email });
              }
            }
          }).catch((err) => {
            console.log(err)
          })
      } else {
        console.log("error")
      }
    }

    QB.webrtc.onRejectCallListener = (session, userId, extension) => {
      console.log("listner_side_when_reject", extension)
      console.log("receiver", session)
      stopCallAudio(true);
      //let opponentId = session?.opponentsIDs[0];
      // session.stop(extension);
      //session.reject(extension);
      this.props.SetOutgoingCallState(false);
      this.props.SetIncomingCallState(false);
      this.props.SetQuickbloxSession(null);
      incomingCallRingtone(false);
      playEndCallAudio();
      //alert('call has been rejected!');
    };
    QB.webrtc.onStopCallListener = (session, userId, extension) => {
      console.log("stop")

      incomingCallRingtone(false);
      stopCallAudio(true);
      this.removeClass();
      session.stop(extension);
      this.props.SetOutgoingCallState(false);
      this.props.SetIncomingCallState(false);
      this.props.SetQuickbloxSession(null);
      this.setState({
        audio: true,
        video: true,
      });

      playEndCallAudio();

      // clearInterval(this.state.timerInterval);
    };
  };

  closeModal = () => {
    this.setState({ scheduleMeeting: false });

    this.props.SetScheduleMeetingState({ state: false });
    console.log("btn close")
  }
  acceptCallHandler = () => {
    console.log("accept call", this.props.session, this.state.extension);
    // console.log("acceptCallHandler calleeName check", this.props.session, calleeName, this.state.extension)
    let { session } = this.props;
    let { extension } = this.state;
    // console.log("now check", session, extension)
    //stopCallAudio(true);
    session.accept(extension);

    // const { minutes, seconds } = this.state;
    // const totalTime = minutes * 60 + seconds;
    // Start the timer
    // this.setState({ timerInterval: setInterval(()=>this.updateTimer(), 1000) });

    // setTimeout(() => {
    //   this.endCallHandler();
    // }, totalTime * 1000);

    this.props.SetIncomingCallState(false);
  };

  // updateTimer = () => {
  //   let { minutes, seconds } = this.state;
  //   const totalTime = minutes * 60 + seconds;

  //   if (totalTime <= 0) {
  //     this.endVideoCall();
  //   } else {
  //     const newTotalTime = totalTime - 1;
  //     minutes = Math.floor(newTotalTime / 60);
  //     seconds = newTotalTime % 60;
  //     this.setState({ minutes, seconds });
  //   }
  // }

  rejectCallHandler = () => {
    let { session } = this.props;
    let { extension } = this.state;
    session.reject({});
    stopCallAudio(true);
    incomingCallRingtone(false);
    this.props.SetIncomingCallState(false);
    this.props.SetOutgoingCallState(false);
    this.props.SetQuickbloxSession(null);
    playEndCallAudio();
    console.log("both_side_reject");
  };

  onSessionStateChange = () => {
    QB.webrtc.onSessionConnectionStateChangedListener = (
      session,
      userId,
      connectionState
    ) => {
      // console.log("On State chnage => ", connectionState);
      // console.log("Session => ", session);
      // console.log("userId => ", userId);
    };
  };

  onEndCall = () => {
    QB.webrtc.onStopCallListener = (session, userId, extension) => {
      playEndCallAudio();
      session.stop({});
      this.props.SetOutgoingCallState(false);
      this.props.SetIncomingCallState(false);
      this.props.SetQuickbloxSession(null);
      this.setState({
        audio: true,
        video: true,
      });
      this.removeClass();
    };
  };

  onSessionClose = () => {
    QB.webrtc.onSessionCloseListener = (session) => {
      this.props.SetIncomingCallState(false);
      stopCallAudio();
      incomingCallRingtone(false);
      this.setState({ audio: true, video: true });
      this.removeClass();
    };
  };

  endCallHandler = () => {
    console.log("stop endcall", this.props.session, this.state.extension);
    playEndCallAudio();
    let { session } = this.props;
    let { extension } = this.state;
    session.stop(extension);
    this.props.SetOutgoingCallState(false);
    this.props.SetIncomingCallState(false);
    this.props.SetQuickbloxSession(null);
    this.setState({
      audio: true,
      video: true,
    });
    this.removeClass();
    // clearInterval(this.state.timerInterval);
  };

  disableCameraHandler = () => {
    this.setState({ video: false });
    this.props.session.mute("video");
  };

  enableCameraHandler = () => {
    this.props.session.unmute("video");
    this.setState({ video: true });
  };

  disableAudioHandler = () => {
    this.setState({ audio: false });
    this.props.session.mute("audio");
  };

  enableAudioHandler = () => {
    this.props.session.unmute("audio");
    this.setState({ audio: true });
  };

  handleSelectedProfile = (id) => {
    fetch(process.env.REACT_APP_QUICKBLOX_URL + id, {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",

      },
    })
      .then(async (response) => {
        if (response && response !== undefined) {
          let data = await response.json();
          if (data && data.length) {
            console.log("data check", data)
            // this.setState({ selectedUserDetails: data });
          }
        }
      }).catch((err) => {
        console.log(err)
      })
  };

  addClass = () => {
    let modalEle = document.getElementById("exampleModal");
    modalEle.style.display = "block";
    document.body.classList.add("modal-open");
  };

  removeClass = () => {
    console.log("removeClass check")
    let modalEle = document.getElementById("exampleModal");
    modalEle.style.display = "none";
    // modalEle.remove();
    document.body.classList.remove("modal-open");
    console.log("modal_closed........")
  };

  onSubscribeListener = () => {
    QB.chat.onSubscribeListener = (userId) => {
      try {
        QB.chat.roster.confirm(userId, () => {
          this.getFriendList();
        });
      } catch (e) {
        console.log(e);
        if (e.name === "ChatNotConnectedError") {
          // not connected to chat
        }
      }
    };
  };

  getFriendList = () => {
    QB.chat.roster.get((contactlist) => {
      const ids = Object.keys(contactlist);
      const newIds = ids.map((id) => Number(id));
      this.getUsersList(newIds);
    });
  };

  // Quickblox Code::End


  getUserByEmail = () => {
    if (User.isLogin()) {
      User.postApi(
        "API_GET_LOGIN_USER",
        {},
        async (response, status) => {
          if (status == "success") {
            this.setState({ fromUserId: response._id })
            this.setState({ fromUserName: response.firstName + " " + response.lastName });
            localStorage.setItem("UserData", JSON.stringify(response));
          } else {
            User.logout()
          }
        },
        true
      );
    }
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const address = results[0].formatted_address;
      var self = this;
      self.setState({ address });
      getLatLng(results[0])
        .then((latLng) => {
          var self = this;
          self.setState({
            lat: latLng.lat,
            lng: latLng.lng,
          });
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleLocation = (event) => {
    this.setState({ searchLocation: event.target.value });
  };

  handleKeyword = (event) => {
    this.setState({ searchKeyword: event.target.value });
  };

  handleCategory = (event) => {
    this.setState({ searchCategoryId: event.target.value });
  };

  ToggleButton = (event) => {
    if (event.target.dataset.status == "Show") {
      this.setState({ searchOptionDisplay: true });
      this.setState({ displayStatus: "Hide" });
    } else {
      this.setState({ searchOptionDisplay: false });
      this.setState({ displayStatus: "Show" });
    }
  };

  readAlertHandler = (id) => {
    socket.emit("readAlert", { id, userId: Category.getAuthUserId() });
    socket.on("getAllAlertsResponse", (data) => {
      this.setState({ Alerts: data });
      let unreadAlert = data.filter((f) => f.read === false);
      this.setState({ unreadAlerts: unreadAlert });
    });
  };


  onChangeInAutocomplete = (event, value) => {

    if (event._reactName !== "onBlur") {
      this.setState({ searchKeyword: value });
    }

    Category.postApi(
      "APIS_ALL_SEARCH_AUTOCOMPLETE_DATA",
      { searchWord: value },
      async (response) => {
        console.log("homeSearchFilterData", response);
        this.setState({ homeSearchFilterData: response });
      },
      false
    );
  };

  onSelectWithFilter = (event, value) => {
    if (value.type == "product") {
      let lat = this.state.lat;
      let lng = this.state.lng;
      let searchKeyword = value.name;
      Category.redirectTo(`search?lat=${lat}&lng=${lng}&keyword=${searchKeyword}`);
    } else if (value.type == "category") {
      let slug = value.slug;
      Category.redirectTo(`category/${slug}`);
    } else if (value.type == "auction_category") {
      let slug = value.slug;
      Category.redirectTo(`live-auction/${slug}`);
    } else if (value.type == "sub_cat") {
      let slug = value.slug;
      if (value.recordType == "Child") {
        slug = `${value.otherSlug}?sctid=${value._id}`;
      }
      Category.redirectTo(`sub-category/${slug}`);
    } else if (value.type == "auction_sub_cat") {
      let slug = value.slug;
      if (value.recordType == "Child") {
        slug = `${value.otherSlug}?sctid=${value._id}`;
      }
      Category.redirectTo(`live-auctions/sub-category/${slug}`);
    } else if (value.type == "cat_type") {
      let slug = `${value.slug}?ctid=${value._id}&keytype=${value.keytype}`;
      if (value.recordType == "Child") {
        slug = `${value.slug}?sctid=${value.otherId}&ctid=${value._id}&keytype=${value.keytype}`;
      }
      Category.redirectTo(`sub-category/${slug}`);
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    let lat = this.state.lat;
    let lng = this.state.lng;
    let searchKeyword = this.state.searchKeyword;
    Category.redirectTo(`search?lat=${lat}&lng=${lng}&keyword=${searchKeyword}`);

  }
  scheduleOnChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (this.state.scheduleDate && name == "scheduleTime") {
      var date = new Date(this.state.scheduleDate);
      let timeArr = value.split(":");
      let hour = timeArr[0];
      let min = timeArr[1];
      date.setHours(hour);
      date.setMinutes(min);

      if (date <= new Date) {
        return;
      }
    }

    this.setState({ [name]: value });

  };
  scheduledCallHandler = () => {
    console.log("props", this.props.scheduleMeetingState)
    console.log("fromUserId", this.state.fromUserId)
    console.log("toUserId from button", this.props.scheduleMeetingState.toUserId)
    console.log("toUserId from callNotPicked", this.state.toUserId)
    let { callNotPicked } = this.state;
    let to_user_id;
    let toUserEmail;
    let toUsername;
    if (callNotPicked) {
      to_user_id = this.state.toUserId;
      toUserEmail = this.state.toUserEmail;
      toUsername = this.state.username;
    } else {
      to_user_id = this.props.scheduleMeetingState.toUserId;
      toUserEmail = this.props.scheduleMeetingState.toUserEmail;
      toUsername = this.props.scheduleMeetingState.username;
    }


    this.setState({ reviewScheduledMeeting: false })
    console.log(",scheduledDate ", this.state.scheduleDate, "formattedScheduledDate", this.state.formattedScheduledDate)

    ScheduleCall.postApi(
      "API_SCHEDULE_CALL",
      {
        date: this.state.scheduleDate,
        time: this.state.formattedScheduledTime,
        fromUserId: this.state.fromUserId,
        fromUserName: this.state.fromUserName,
        toUserId: to_user_id,
        fromQbUserId: this.state.fromQbUserId,
        toQbUserId: this.state.toQbUserId,
        callNotPicked: this.state.callNotPicked,
        scheduleEndTime: this.state.scheduleEndTime
      },
      async (response, data) => {
        console.log("response", response)
        console.log("data", data);
        console.log("from name", this.state.fromUserName, "from email", this.state.email, "to name", toUsername, "to email", toUserEmail)
        this.sendAutoMessageOnChat(this.state.email, `Call Scheduled with ${toUsername}`);
        this.sendAutoMessageOnChat(toUserEmail, `Call Scheduled with ${this.state.fromUserName}`);

        if (data == "success") {
          this.props.SetScheduleMeetingState({ updateInList: true });
        }
        console.log("success")
      },
      true
    );
  };
  sendAutoMessageOnChat = (email, msg) => {
    var searchParams = { login: email };
    console.log("searchParams", searchParams)
    QB.users.get(searchParams, function (error, user) {
      console.log("user", user)
      if (!error) {
        // const contacts = JSON.parse(
        //   localStorage.getItem("QB_CONTACT_LIST")
        // );
        // console.log('products', products.allowBuyNow);
        //console.log('user', user);
        // let allContacts = [];

        // const restContacts = contacts.filter(
        //    (user1) => user1.user.id !== user.id
        // );

        // allContacts = [{ user }, ...restContacts];
        // localStorage.setItem(
        //   "QB_CONTACT_LIST",
        //   JSON.stringify(allContacts)
        // );
        // this.props.SetContactList(allContacts);
        let params = {
          type: 3,
          occupants_ids: [user.id],
        };
        console.log("params", params)
        QB.chat.dialog.create(params, (error, dialog) => {
          console.log("dialog", dialog)
          if (!error) {
            let message = {
              type: "chat",
              body: msg,
              extension: {
                save_to_history: 1,
                dialog_id: dialog._id,
              },
              markable: 1,
            };
            QB.chat.send(user.id, message);
          } else {
            console.log("Dialog Create Error", error);
          }
        });
      }
    });
  };

  editScheduledEvent = () => {
    let { formattedScheduledDate, formattedScheduledTime } = this.state;
    ScheduleCall.postApi(
      "API_EDIT_SCHEDULED_CALL",
      {
        date: formattedScheduledDate,
        time: formattedScheduledTime,
      },
      async (response) => {
        console.log("success")
      },
      true
    );
  }
  deleteScheduledCall = () => {
    let { toUserId } = this.state;
    ScheduleCall.postApi(
      "API_DELETE_SCHEDULED_CALL",
      {
        toUserId: toUserId
      },
      async (response) => {
        console.log("success")
      },
      true
    );
  }
  nameOnModal = () => {
    // console.log("name");
    // console.log("not pick", this.state.username);
    // console.log("button", this.props.scheduleMeetingState.username)
    let { callNotPicked } = this.state;
    let to_user_name;
    if (callNotPicked) {
      to_user_name = this.state.username;

    } else {
      to_user_name = this.props.scheduleMeetingState.username;
    }
    return to_user_name;
  }


  render() {
    // let { minutes, seconds} = this.state;
    let { calleeName, profileMenuShow } = this.state;
    let { userNotAnswered, scheduleMeeting, reviewScheduledMeeting, formattedScheduledDate, formattedScheduledTime, scheduleDate, scheduleTime, username, customCalendarClass } = this.state;
    console.log("profileMenuShow", profileMenuShow);
    const renderOption = (option) => {
      return (
        <div className="normal">
          <span className="text">{option.name}</span>
          {option?.parentName &&
            <> - <span className="text-color-sky"> {option?.parentName} {option.type === "auction_sub_cat" && "(In Auction Show)"}</span></>}
        </div>
      );
    };

    return (
      <header>
        <Modal
          isOpen={userNotAnswered}
          style={customStyles}
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
          className="shipModal"
        >
          <button onClick={() => this.setState({
            userNotAnswered: false,
          })} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <div className="income_heading py-2">
            <h3>Would you like to schedule video chat with {this.nameOnModal()}?</h3>
          </div>
          <div className="btn-block text-center">
            <button
              className="btn btn-success mr-2"
              onClick={() => this.setState({ userNotAnswered: false, scheduleMeeting: true })}
            >
              Yes
            </button>
            <button
              className="btn btn-danger"
              onClick={() => this.setState({ userNotAnswered: false })}

            >
              No
            </button>
          </div>
        </Modal>
        {/* {console.log("test", this.props.scheduleMeetingState)} */}
        {this.props.scheduleMeetingState.state || scheduleMeeting ? (
          <Modal
            isOpen={true}
            style={customStyles}
            contentLabel="schedule Meeting"
            ariaHideApp={false}
            className={customCalendarClass}
          >
            <button onClick={() => this.closeModal()} className="shipCloseBtn">
              <i className="fa fa-times"></i>
            </button>
            <div className="income_heading py-2">
              <h3>Schedule video chat with {this.nameOnModal()}</h3>
            </div>

            <div className="d-md-flex d-flex">
              <div className="Schedule_date_time mb-3 mb-md-0">

                <DatePicker
                  name="scheduleDate"
                  onChange={(value) =>
                    this.setState({
                      scheduleDate: value,

                    })
                  }
                  minDate={new Date()}
                  value={this.state.scheduleDate}
                  //className="fixCalendar"
                  onCalendarOpen={() =>
                    this.setState({
                      customCalendarClass: "shipModal fixCalendar",
                    })
                  }
                  onCalendarClose={() =>
                    this.setState({
                      customCalendarClass: "shipModal",
                    })
                  }
                />

              </div>

              <div className="Schedule_date_time mb-3 mb-md-0">
                <TextField
                  type="time"
                  //mintime='9:00'
                  name="scheduleTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    this.state.scheduleTime
                  }
                  style={{ width: "100%" }}
                  onChange={
                    this.scheduleOnChangeHandler
                  }
                />
              </div>

            </div>
            <div className="btn-block text-center">
              <button
                className="btn btn-primary mt-4"
                onClick={() => {
                  console.log("scheduleDate", scheduleDate, "scheduleTime", scheduleTime)
                  let scDate = scheduleDate.getFullYear() + "-" + (scheduleDate.getMonth() + 1) + "-" + scheduleDate.getDate();
                  console.log("scDate", scDate)
                  let dateTime = scDate + " " + scheduleTime;
                  let dateTi = new Date(dateTime);
                  dateTi.setMinutes(dateTi.getMinutes() + 15);

                  let endTime = dateTi.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                  console.log("endtime", endTime)

                  this.setState({ reviewScheduledMeeting: true, scheduleMeeting: false, formattedScheduledDate: scDate, formattedScheduledTime: scheduleTime, scheduleEndTime: endTime });
                  this.props.SetScheduleMeetingState({ state: false });
                }}
              >
                Review
              </button>
            </div>
          </Modal>
        ) : null}
        <Modal
          isOpen={reviewScheduledMeeting}
          style={customStyles}
          contentLabel="Review scheduled Meeting"
          ariaHideApp={false}
          className="shipModal"
        >
          <button onClick={() => this.setState({
            reviewScheduledMeeting: false,
          })} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <div class="align-items-center d-inline-flex"><div class="musr_img"><img src="/images/user.png" alt="image" class="mCS_img_loaded" /></div><h4 class="ml-3">{this.nameOnModal()}</h4></div>
          <div className="income_heading py-2">
            <h3>Scheduled for {this.state.formattedScheduledDate} at {this.state.formattedScheduledTime}</h3>
          </div>
          <div className="btn-block text-center">
            <button
              className="btn btn-primary mr-2"
              onClick={() => this.scheduledCallHandler()}
            >
              Schedule video chat
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.props.outgoingCallState}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
          className="shipModal"
        >
          <button onClick={() => this.rejectCallHandler()} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>

          <div className="video_modal_income">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header p-0 border-0">
                </div>
                <div className="modal-body">
                  <div className="income_heading text-center py-2">
                    <h3>Outgoing call....</h3>
                    <div className="call_optn">
                      {this.props.session &&
                        this.props.session.callType === 1 &&
                        (this.state.video ? (
                          <a href={void (0)} class="video_icon" onClick={this.disableCameraHandler}><i className="fas fa-video"></i>{" "}</a>
                        ) : (
                          <a href={void (0)} class="video_icon" onClick={this.enableCameraHandler}>
                            <i className="fas fa-video-slash"></i>{" "}
                          </a>
                        ))}
                      {this.state.audio ? (
                        <a href={void (0)} class="video_icon" onClick={this.disableAudioHandler}>
                          {" "}
                          <i className="fas fa-microphone"></i>{" "}
                        </a>
                      ) : (
                        <a href={void (0)} class="video_icon" onClick={this.enableAudioHandler}>
                          {" "}
                          <i className="fas fa-microphone-slash"></i>{" "}
                        </a>
                      )
                      }
                      <a href={void (0)} class="video_cut_icon" onClick={() => this.rejectCallHandler()}><i class="fa fa-phone fa-rotate-360 fa-2xs"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.props.incomingCallState}
          // this.props.incomingCallState
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
          className="shipModal"//
        >
          <button onClick={() => this.rejectCallHandler()} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>

          <div className="video_modal_income">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="income_heading py-2">
                    <h3> {calleeName} is calling...</h3>
                    <span>The call will start as soon as you accept</span>
                  </div>

                  <div className="btn-block text-right">
                    <button
                      className="btn btn-success mr-2"
                      onClick={() => this.acceptCallHandler()}
                    >
                      <i class="fa fa-video"></i>
                      <span className="incoming_call_btns">Accept</span>
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => this.rejectCallHandler()}

                    >
                      <i class="fa fa-times"></i>
                      <span className="incoming_call_btns">Reject</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Modal Code::Start */}
        <div
          className="modal fade video_modal show "
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header p-0 border-0">
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  // aria-label="Close"
                  onClick={this.endCallHandler}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="live_action">
                  <div className="cont_bx">
                    <div className="img_block">
                      <video
                        id="remoteVideoElem"
                        autoPlay
                        poster={
                          this.props.session &&
                            this.props.session.callType === 2
                            ? process.env.REACT_APP_FRONTEND_URL +
                            "/images/user.png"
                            : ""
                        }
                        height={
                          this.props.session &&
                            this.props.session.callType === 2
                            ? 480
                            : ""
                        }
                      ></video>
                    </div>

                    <div className="other_user">
                      <video
                        id="localVideoElem"
                        autoPlay
                      // poster={
                      //   this.props.session &&
                      //   this.props.session.callType === 2
                      //     ? process.env.REACT_APP_FRONTEND_URL +
                      //       "/images/user.png"
                      //     : ""
                      // }
                      ></video>
                    </div>
                    <div className="action_icon">
                      {/* <div>Time Remaining: {minutes}m {seconds}s</div> */}
                      {this.props.session &&
                        this.props.session.callType === 1 &&
                        (this.state.video ? (
                          <a href={void (0)} onClick={this.disableCameraHandler}>
                            <i className="fas fa-video"></i>{" "}
                          </a>
                        ) : (
                          <a href={void (0)} onClick={this.enableCameraHandler}>
                            <i className="fas fa-video-slash"></i>{" "}
                          </a>
                        ))}
                      {this.state.audio ? (
                        <a href={void (0)} onClick={this.disableAudioHandler}>
                          {" "}
                          <i className="fas fa-microphone"></i>{" "}
                        </a>
                      ) : (
                        <a href={void (0)} onClick={this.enableAudioHandler}>
                          {" "}
                          <i className="fas fa-microphone-slash"></i>{" "}
                        </a>
                      )}

                      <a href={void 0} onClick={() => this.endCallHandler()}>
                        {" "}
                        <i className="fas fa-phone-slash"></i>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Code::End */}
        <section className="top-header">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-6 ">
                <div className="logo-bx">
                  {this.state.headerLogo && (
                    <Link to="/">
                      <img
                        src={this.state.headerLogo}
                        alt="logo.png"
                      />
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="top-right-anchor">
                  <ul>
                    <li
                      // className="search-toggle-btn"
                      onClick={this.ToggleButton}
                      data-status={this.state.displayStatus}
                    >
                      <Link
                        to="#"
                        onClick={this.ToggleButton}
                        data-status={this.state.displayStatus}
                      >
                        <span>
                          <img
                            src="/images/search-icon-new.svg"
                            data-status={this.state.displayStatus}
                            width="14"
                            height="14"
                            alt="search-icon.png"
                          />
                        </span>{" "}
                        {/* Browse */}
                      </Link>
                    </li>
                    <li className="desktop_none">
                      <a href="/post-an-ad" className="sell_btn"> Sell</a>
                    </li>
                    {User.isLogin() && (
                      <li className="mobile_none">
                        <Link to="/post-an-ad">
                          <span className=" d-lg-inline-block">
                            <img
                              src="/images/post-ad-icon.svg"
                              width="14"
                              height="14"
                              alt="post-ad-icon.png"
                              title="Sell"
                            />

                          </span>{" "}
                        </Link>
                      </li>
                    )}
                    <li className="mobile_none">
                      <Link
                        to={User.isLogin() ? `/UserMessageBox` : `/login`}
                      >
                        <span className="position-relative">
                          <img src="/images/act02_grey.png" alt="icon" />
                          {this.props.unreadMessage > 0 &&
                            <span className="live_dot_bell message_notification"></span>

                          }
                        </span>{" "}

                      </Link>
                    </li>
                    <li className="dropdown mobile_none">
                      <a
                        className=" dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="position-relative">
                          <img
                            src="/images/bell-icon-2.svg"
                            width="14"
                            height="14"
                            alt="wishlist-icon.png"
                            title="Alert"
                          />
                          {this.state.unreadAlerts.length > 0 && (
                            <span className="live_dot_bell"></span>
                          )}
                        </span>{" "}
                        {/* <span className="d-none d-lg-inline-block"> Alert</span> */}
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="event-list-box-cover">
                          <div className="event-name ">
                            <div className="row">
                              <div className="col-6 text-left">
                                <h4>
                                  <span className="d-none d-lg-inline-block">
                                    {" "}
                                    My Alerts
                                  </span>
                                  ({this.state.unreadAlerts.length})
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className="event-list-box " data-mcs-theme="dark">
                            <ul className="event-list">
                              {this.state.Alerts &&
                                this.state.Alerts.length > 0 ? (
                                this.state.Alerts.map((alert) => (
                                  <li key={alert._id}>
                                    <div className="row m-0 align-items-center w-100">
                                      <div className="col p-0 col-profile alert_notifi">
                                        <div>
                                          <i
                                            className="far fa-bell"
                                            aria-hidden="true"
                                          ></i>
                                          <span className="live-dot"></span>
                                        </div>
                                        <h6>
                                          <Moment fromNow ago>
                                            {alert.time}
                                          </Moment>
                                        </h6>
                                        {/* <a href="#" className="overlay-a"></a> */}
                                      </div>
                                      <div className="col p-0">
                                        <Link
                                          to={alert.link ? alert.link : ""}
                                          className="notififaction-test"
                                        >
                                          <p>{alert.type}</p>
                                          <strong>{alert.message}</strong>
                                        </Link>
                                      </div>
                                      <div className="col p-0 time">
                                        <h5>
                                          <Link
                                            // href="#"
                                            to="#"
                                            onClick={() =>
                                              this.readAlertHandler(alert._id)
                                            }
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Mark as read"
                                          >
                                            <i
                                              className={`far fa-envelope${alert.read === true
                                                ? "-open"
                                                : ""
                                                }`}
                                            ></i>
                                          </Link>
                                        </h5>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="no_alerts">No Alerts</div>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown usermenu_drop">
                        {User.isLogin() ? (
                          <>
                            <a
                              className=""
                              id=""
                              // data-toggle="dropdown"
                              // aria-haspopup="true"
                              // aria-expanded="false"
                              onClick={this.updateFilterClass}
                            >
                              <span className="user-icon">
                                {this.state.profilePicture ? (
                                  <img
                                    src={General.showImageNew(
                                      "profile",
                                      this.state.profilePicture
                                    )}
                                    onError={
                                      (e) => e.target.src = '/images/user.png'
                                    }
                                    width="14"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/images/user.png"
                                    width="14"
                                    alt="user-icon.png"
                                  />
                                )}
                              </span>
                            </a>
                            {
                              this.state.profileMenuShow && (
                                <div
                                  className="dropdown-menu-2"
                                  onClick={() => {
                                    document.body.classList.remove('filter-open');
                                  }}
                                >
                                  <Link
                                    className="dropdown-item"
                                    to="/my-profile"
                                  >
                                    Profile{" "}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/my-profile/activity"
                                  >
                                    Activity{" "}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/my-profile/balance"
                                  >
                                    Balance{" "}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/my-profile/premium-services"
                                  >
                                    Premium Services{" "}
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    to="/my-profile/account-settings"
                                  >
                                    {" "}
                                    Account Settings{" "}
                                  </Link>
                                  <Link
                                    to="#"
                                    className="dropdown-item"
                                    onClick={User.logout}
                                  >
                                    {" "}
                                    Logout
                                  </Link>
                                  <span className="cross_icon" onClick={this.handleOverlayClick}>
                                    <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M16.0673 15.1828C16.1254 15.2409 16.1714 15.3098 16.2028 15.3857C16.2343 15.4616 16.2505 15.5429 16.2505 15.625C16.2505 15.7071 16.2343 15.7885 16.2028 15.8643C16.1714 15.9402 16.1254 16.0091 16.0673 16.0672C16.0092 16.1253 15.9403 16.1713 15.8644 16.2028C15.7885 16.2342 15.7072 16.2504 15.6251 16.2504C15.543 16.2504 15.4617 16.2342 15.3858 16.2028C15.3099 16.1713 15.241 16.1253 15.1829 16.0672L10.0001 10.8836L4.81729 16.0672C4.70002 16.1845 4.54096 16.2504 4.3751 16.2504C4.20925 16.2504 4.05019 16.1845 3.93292 16.0672C3.81564 15.9499 3.74976 15.7909 3.74976 15.625C3.74976 15.4592 3.81564 15.3001 3.93292 15.1828L9.11651 10L3.93292 4.8172C3.81564 4.69992 3.74976 4.54086 3.74976 4.37501C3.74976 4.20916 3.81564 4.0501 3.93292 3.93282C4.05019 3.81555 4.20925 3.74966 4.3751 3.74966C4.54096 3.74966 4.70002 3.81555 4.81729 3.93282L10.0001 9.11642L15.1829 3.93282C15.3002 3.81555 15.4593 3.74966 15.6251 3.74966C15.791 3.74966 15.95 3.81555 16.0673 3.93282C16.1846 4.0501 16.2505 4.20916 16.2505 4.37501C16.2505 4.54086 16.1846 4.69992 16.0673 4.8172L10.8837 10L16.0673 15.1828Z" fill="white" />
                                    </svg>
                                  </span>
                                </div>
                              )
                            }

                          </>
                        ) : (
                          <Link to="/login">Login &nbsp; / Register</Link>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<div className={this.state.searchOptionDisplay ? 'onSearchClick-content onSearchClick-fixed' : 'onSearchClick-content'}>*/}
        <div
          className={
            this.state.searchOptionDisplay
              ? "onSearchClick-content onSearchClick-fixed"
              : "onSearchClick-content"
          }
        >
          <div className="header-search-placeholder"></div>
          <section className="header-body">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col logo-bx-outer desktop-stuff searchLogo"
                  id="BrowseLogo"
                >
                  <div className="logo-bx-search">
                    {this.state.headerLogo && (
                      <Link to="/">
                        <img
                          src={this.state.headerLogo}
                          alt="logo.png"
                        />
                      </Link>
                    )}
                  </div>
                </div>
                <div className="col desktopno_search">
                  <form onSubmit={this.submitHandler}>
                    <div className="header-search-bx">
                      <h2>Search & Buy from Sellers</h2>
                      <p>
                        Post products for free, Sell faster with live auction show
                      </p>
                      <div className="header-search-inner-bx">
                        <div className="search-and-location">
                          <Autocomplete
                            onChange={(event, value) => this.onSelectWithFilter(event, value)}
                            onInputChange={(event, value) => this.onChangeInAutocomplete(event, value)}
                            className="header-input"
                            disablePortal={true}
                            options={this.state.homeSearchFilterData}
                            getOptionLabel={(option) => option.name}
                            renderOption={renderOption}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search for everything . . ."
                              // inputProps={{ ...params.inputProps, maxLength: 10}}
                              />
                            )}
                          />
                        </div>
                        <div className="search-btn">
                          <button type="submit" className="search-btn-link">
                            <img
                              src="/images/search-icon.png"
                              width="18"
                              height="18"
                              alt="search-icon.png"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        {!this.props.isClosedMessageBox && Product.isLogin() && <Message />}
        {/* <Message /> */}
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isClosedMessageBox: state.MESSAGE_BOX.isClosedMessageBox,
    outgoingCallState: state.VIDEO_CALL.outgoingCallState,
    incomingCallState: state.VIDEO_CALL.incomingCallState,
    scheduleMeetingState: state.VIDEO_CALL.scheduleMeetingState,
    session: state.VIDEO_CALL.quickbloxSession,
    unreadMessage: state.MESSAGE_BOX.unreadMessage
  };
};

export default connect(mapStateToProps, {
  SetQuickblox,
  CloseMessageBox,
  SetOutgoingCallState,
  SetIncomingCallState,
  SetScheduleMeetingState,
  SetQuickbloxSession,
  SetContactList,
  SetQuickbloxUnreadMessage
})(Header);
