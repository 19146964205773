import React, { Component } from "react";
import NewsLetterForm from "Forms/NewsLetterForm";
import { Link } from "react-router-dom";
import Settings from "Models/Settings";
import User from "Models/User";
let baseUrl = process.env.REACT_APP_ApiBaseUrl;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerLogo: "",
      websiteSettingsData: localStorage.getItem("websiteSettingsData")
        ? JSON.parse(localStorage.getItem("websiteSettingsData"))
        : {},
      generalSettingsData: localStorage.getItem("generalSettingsData")
        ? JSON.parse(localStorage.getItem("generalSettingsData"))
        : {},
    };
  }

  componentDidMount = () => {
    Settings.getApi(
      "APIS_GET_GENERAL_SETTING",
      async (response) => {
        localStorage.setItem(
          "generalSettingsData",
          JSON.stringify(response[0])
        );
        this.setState({ generalSettingsData: response[0] });
      },
      false
    );
    User.getApi("APIS_Get_logo", async (response) => {
      //  console.log("response",response.headerLogo);
      let headerLogo = `${baseUrl}image/logo/${response.headerLogo}`;
      let footerLogo = `${baseUrl}image/logo/${response.footerLogo}`;
      let mobileLogo = `${baseUrl}image/logo/${response.mobileLogo}`;
      this.setState({ headerLogo: headerLogo });
      this.setState({ footerLogo: footerLogo });
      this.setState({ mobileLogo: mobileLogo });
      localStorage.setItem("HeaderLogo", headerLogo);
      localStorage.setItem("FooterLogo", footerLogo);
      localStorage.setItem("MobileLogo", mobileLogo);
    });
  };

  componentWillMount = () => {
    var self = this;
    Settings.getApi(
      "APIS_GET_SETTING_DATA",
      async (response) => {
        localStorage.setItem("websiteSettingsData", JSON.stringify(response));
        self.setState({ websiteSettingsData: response });
      },
      false
    );
  };

  render() {
    const { websiteSettingsData } = this.state;

    return (
      <footer className="site-footer">
        <div className="container">
          <NewsLetterForm />
          <div className="row justify-content-center mb-3">
            <div className="col-lg-3">
              <div className="ftr-about-bx">
                <div className="ftr-logo">
                  {this.state.footerLogo && (
                    <a href="/">
                      <img src={this.state.footerLogo} alt="ftr-logo.png" />
                    </a>
                  )}
                  {/* <p>{websiteSettingsData.websiteabout}</p> */}
                </div>
                {/* <div className="ftr-menu-bxx">
                  <ul className="mob-icon-center">
                    <li>
                      <a
                        target="_blank"
                        href={
                          this.state.generalSettingsData["miscellaneous"]
                            ? this.state.generalSettingsData[
                                "miscellaneous"
                              ][0]["facebook"]
                            : "https://facebook.com"
                        }
                      >
                        <span>
                          <i className="fab fa-facebook-f"></i>
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href={
                          this.state.generalSettingsData["miscellaneous"]
                            ? this.state.generalSettingsData[
                                "miscellaneous"
                              ][0]["twitter"]
                            : "https://twitter.com"
                        }
                      >
                        <span>
                          <i className="fab fa-twitter"></i>
                        </span>{" "}
                      </a>
                    </li>
                  </ul>
                </div> */}
                {/* <ul className="contact-ul">
                  <li>
                    <span>
                      <img
                        src="https://dlevel.b-cdn.net/images/hotline-icon.png"
                        width="33"
                        height="36"
                        alt="hotline-icon"
                      />
                    </span>{" "}
                    <p>
                      <small>Hotline 24/7:</small>{" "}
                      {websiteSettingsData.contactnumber}
                    </p>
                  </li>
                  <li>
                    <span>
                      <img
                        src="https://dlevel.b-cdn.net/images/email-icon.png"
                        width="20"
                        height="14"
                        alt="email-icon"
                      />
                    </span>{" "}
                    <a>{websiteSettingsData.contactemail}</a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-6 col-md-2 padding_left">
              <div className="ftr-menu-bx">
                <h2>Information</h2>
                <ul>
                  <li>
                    <a href="/about-us">About us</a>
                  </li>
                  <li>
                    <a href="/careers">Careers</a>
                  </li>
                  {/* <li>
                    <a href="/technical-issues">Technical issues</a>
                  </li> */}
                  <li>
                    <a href="/contact-us">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-2 padding_left">
              <div className="ftr-menu-bx">
                <h2>Support</h2>
                <ul>
                  <li>
                    <a href="/help-center">Help Center</a>
                  </li>
                  <li>
                    <a href="/learn-to-sell">Selling</a>
                  </li>
                  <li>
                    <a href="/learn-to-buy">Buying</a>
                  </li>
                  <li>
                    <a href="/safety-tips">Safety Tips</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-bx">
            <div className="copyright-txt">{websiteSettingsData.copyright}</div>
            <div className="privacy-bx">
              {/* <a href="/contact-us" className="display-contact">
                Contact Us
              </a> */}
              {/* <span className="display-contact">|</span> */}
              <a href="/term-conditions">Terms of Services</a>
              <span>|</span>
              <a href="/privacy-policy">Privacy Policy</a>
              <span>|</span>
              <a href="/cookies-policy">Cookies policy</a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
