import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Product from "Models/Product";
import ProductReview from "Models/ProductReview";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Storage from "Helpers/Storage";
import StarRatings from "react-star-ratings";
import { LinearProgress, TextField } from "@material-ui/core";
import { Rating } from "react-simple-star-rating";

export class CustomerReviewComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["_id"]
                : "",
            profilePicture: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["profilePicture"]
                : "",
            allReviews: localStorage.getItem("MyReviews")
                ? JSON.parse(localStorage.getItem("MyReviews"))
                : [],
            allReviewsList: localStorage.getItem("MyReviews")
                ? JSON.parse(localStorage.getItem("MyReviews"))
                : [],
            avgRating: localStorage.getItem("MyAvgRating")
                ? JSON.parse(localStorage.getItem("MyAvgRating"))
                : 0,
            showReplyAsSeller: "",
            reply: "",
        };

    }

    componentDidMount = () => {
        this.getAllProducts();
    };

    toggleReplyAsSeller = (e, id) => {
        e.preventDefault();
        const { showReplyAsSeller } = this.state;

        showReplyAsSeller === id
            ? this.setState({ showReplyAsSeller: "" })
            : this.setState({ showReplyAsSeller: id, reply: "" });
    };

    handleReplySubmit = async (e, id) => {
        e.preventDefault();
        const { reply } = this.state;

        if (reply != "") {
            var review_error = document.getElementsByClassName("review-error-" + id);
            for (var i = 0; i < review_error.length; i++) {
                review_error[i].innerHTML = "";
            }
            if (
                (await ProductReview.checkValidation(
                    await ProductReview.validationRules(),
                    { reply }
                )) === true
            ) {
                Product.postApi(
                    "APIS_POST_REPLY_REVIEW",
                    { id, reply },
                    async (response) => {
                        this.setState({ showReplyAsSeller: "", reply: "" });
                        this.getAllProducts();
                    },
                    true
                );
            }
        } else {
            var review_error = document.getElementsByClassName("review-error-" + id);
            for (var i = 0; i < review_error.length; i++) {
                review_error[i].innerHTML = "Please add review.";
            }
        }
    };

    filterWithRating = (e) => {
        if (e > 0) {
            let allReviews = this.state.allReviews.filter((review) => review.rating === e);
            this.setState({ allReviewsList: allReviews });
        } else {
            let allReviews = this.state.allReviews;
            this.setState({ allReviewsList: allReviews });
        }
    }

    getAllProducts = () => {
        MyProfile.getApi(
            "APIS_GET_MY_PROFILE_PRODUCT",
            async (response) => {
                Storage.setLocalData("MyProducts", JSON.stringify(response.products));
                Storage.setLocalData("MyReviews", JSON.stringify(response.reviews));
                Storage.setLocalData("MyAvgRating", JSON.stringify(response.avgRating));
                this.setState({ allReviews: response.reviews });
                this.setState({ allReviewsList: response.reviews });
                this.setState({ avgRating: response.avgRating });
            },
            true
        );
    };

    render() {
        const { allReviews, allReviewsList } = this.state
        return (
            <>
                {/* {isLoading && (
          <div class="loader-wrapper" id="loader_img">
            <div class="loader">
              <img src="https://dlevel.b-cdn.net/images/logo.png" alt="" />
              <div class="material-spinner"></div>
            </div>
          </div>
        )} */}
                <div className="tab-pane active in this_subManage_Listings">
                    <div className="row align-items-en px-4 pt-4 desktop_view">
                        <div className="col-xl-12 customer-review-bx customer-review-bx-mobile d-flex justify-content-between">
                            <h2 className="padding-mobile">
                                {allReviews && allReviews.length} Reviews from  Customers
                            </h2>
                            <div className="d-flex my-custom-tab-mobile">
                                <div className="star star-filter" onClick={() => this.filterWithRating(0)}>
                                    <span>
                                        (All)
                                    </span>
                                </div>
                                <div className="star star-filter col d-content" onClick={() => this.filterWithRating(5)}>
                                    <span>
                                        (5)
                                    </span>
                                    <StarRatings
                                        rating={5}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                    />
                                </div>
                                <div className="star star-filter col d-content" onClick={() => this.filterWithRating(4)}>
                                    <span>
                                        (4)
                                    </span>
                                    <StarRatings
                                        rating={4}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                    />
                                </div>
                                <div className="star star-filter col d-content" onClick={() => this.filterWithRating(3)}>
                                    <span>
                                        (3)
                                    </span>
                                    <StarRatings
                                        rating={3}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                    />
                                </div>
                                <div className="star star-filter col d-content" onClick={() => this.filterWithRating(2)}>
                                    <span>
                                        (2)
                                    </span>
                                    <StarRatings
                                        rating={2}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                    />
                                </div>
                                <div className="star star-filter col d-content" onClick={() => this.filterWithRating(1)}>
                                    <span>
                                        (1)
                                    </span>
                                    <StarRatings
                                        rating={1}
                                        starRatedColor="#249ee4"
                                        starDimension="17px"
                                        starSpacing="1px"
                                        numberOfStars={5}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 padding-mobile">
                            <div className="customer-review-bx">
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">5 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                5
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                5
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(5)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 5)})
                                        </span>
                                    </div>
                                </div>
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">4 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                4
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                4
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(4)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 4)})
                                        </span>
                                    </div>
                                </div>
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">3 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                3
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                3
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(3)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 3)})
                                        </span>
                                    </div>
                                </div>
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">2 Stars</div>
                                    <div className="rating-view col">

                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                2
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                2
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(2)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 2)})
                                        </span>
                                    </div>
                                </div>
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">1 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                1
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                1
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(1)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 1)})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="customer-review-profile_block bg-white col-xl-8 ">
                            <div className="row pb-3">
                                <div className="col-md-3">
                                    <div className="view-more-review">
                                        View
                                        <b>
                                            {" "}
                                            {allReviewsList && allReviewsList.length}{" "}
                                        </b>{" "}
                                        reviews
                                    </div>{" "}
                                </div>
                                <div className="col-md-3">Share your reviews</div>
                                <div className="col-md-3">
                                    <div className="review-share">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fal"
                                            data-icon="share"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512"
                                            className="svg-inline--fa fa-share fa-w-18 fa-2x"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M564.907 196.35L388.91 12.366C364.216-13.45 320 3.746 320 40.016v88.154C154.548 130.155 0 160.103 0 331.19c0 94.98 55.84 150.231 89.13 174.571 24.233 17.722 58.021-4.992 49.68-34.51C100.937 336.887 165.575 321.972 320 320.16V408c0 36.239 44.19 53.494 68.91 27.65l175.998-184c14.79-15.47 14.79-39.83-.001-55.3zm-23.127 33.18l-176 184c-4.933 5.16-13.78 1.73-13.78-5.53V288c-171.396 0-295.313 9.707-243.98 191.7C72 453.36 32 405.59 32 331.19 32 171.18 194.886 160 352 160V40c0-7.262 8.851-10.69 13.78-5.53l176 184a7.978 7.978 0 0 1 0 11.06z"
                                                className=""
                                            ></path>
                                        </svg>
                                        Share
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="rated-customer-bx-inner">
                                        <div className="rated-star text-left">
                                            <span>Rated</span>
                                            <StarRatings
                                                rating={this.state.avgRating}
                                                starRatedColor="#249ee4"
                                                starDimension="20px"
                                                starSpacing="0"
                                                numberOfStars={5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {Product.isLogin() && (
                                <div className="review_mess">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="review_reply pr-0 ">
                                            <div className="row w-100">
                                                <div className="col-auto">
                                                    <div className="detail-owner-name-bx clearfix">
                                                        <div className="detail-owner-img">
                                                            {this.state.profilePicture ? (
                                                                <img
                                                                    src={General.showImageNew(
                                                                        "profile",
                                                                        this.state.profilePicture
                                                                    )}
                                                                    alt={this.state.profilePicture}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/images/no-image.jpg"
                                                                    alt="image1"
                                                                />
                                                            )}

                                                            <span className="live-dot"></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col pl-3">
                                                    <div className="detail-owner-name pl-0">
                                                        <div className="rated-customer-bx-inner mess-sender-name">
                                                            <h2>{this.state?.name}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea
                                                            name="review"
                                                            value={this.state?.review}
                                                            onChange={this.handleChange}
                                                            className="form-control-line h-20px"
                                                            rows="2"
                                                            placeholder="share your review"
                                                            maxLength={this.state?.maxLength}
                                                        ></textarea>
                                                    </div>
                                                    <div className="d-flex">
                                                        {this.state?.review?.length}
                                                        {this.state?.maxLength}
                                                        <div className="flex-g">
                                                            <div className="rated-star mr-4">
                                                                <Rating
                                                                    name="rating"
                                                                    value={this.state?.rating}
                                                                    onChange={(event, newValue) => {
                                                                        this.setState({
                                                                            rating: event.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <button
                                                                    type="submit"
                                                                    className="btnx btn-secondaryx"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}
                            {console.log("jont", allReviewsList)}
                            {allReviewsList &&
                                allReviewsList.map((review) => (
                                    <div className="review_block" key={`all_review_${review._id}`}>
                                        <div className="row align-items-center">
                                            <div className="col-lg-9">
                                                <div className="detail-owner-name-bx clearfix review_author align-items-start">
                                                    <div className="detail-owner-img">
                                                        {review.userId &&
                                                            review.userId.profilePicture ? (
                                                            <img
                                                                src={General.showImageNew(
                                                                    "profile",
                                                                    review.userId.profilePicture
                                                                )}
                                                                alt={review.userId.profilePicture}
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/images/no-image.jpg"
                                                                alt="image"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="detail-owner-name">
                                                        <h3>
                                                            {review.userId &&
                                                                review.userId.firstName}{" "}
                                                            {""}{" "}
                                                            {review.userId &&
                                                                review.userId.lastName}
                                                        </h3>
                                                        <div className="rated-customer-bx-inner">
                                                            <div className="rated-star">
                                                                <span>Rated</span>
                                                                <span></span>
                                                                <StarRatings
                                                                    rating={review.rating}
                                                                    starRatedColor="#249ee4"
                                                                    starDimension="20px"
                                                                    starSpacing="0"
                                                                    numberOfStars={5}
                                                                />
                                                            </div>
                                                            <h2>{review.review}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="rated-customer-bx d-block text-right">
                                                    <Link
                                                        onClick={(e) =>
                                                            this.toggleReplyAsSeller(
                                                                e,
                                                                review._id
                                                            )
                                                        }
                                                        className="number-reveal-btn"
                                                    >
                                                        <img
                                                            src="/images/reply-icon.png"
                                                            alt="icon"
                                                        />{" "}
                                                        <span>Reply As Seller</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            {review.replies &&
                                                review.replies.map((reviewReply) => (
                                                    <div className="review_reply" key={`allcus_${reviewReply._id}`}>
                                                        <div className="row align-items-center w-100 no-gutters">
                                                            <div className="col-auto">
                                                                <div className="detail-owner-name-bx clearfix review_author align-items-start py-0">
                                                                    <div className="detail-owner-img reply_img_b">
                                                                        {reviewReply.userId &&
                                                                            reviewReply.userId
                                                                                .profilePicture ? (
                                                                            <img
                                                                                src={General.showImageNew(
                                                                                    "profile",
                                                                                    reviewReply.userId
                                                                                        .profilePicture
                                                                                )}
                                                                                alt={
                                                                                    reviewReply.userId
                                                                                        .profilePicture
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                className="live-dot"
                                                                                src="/images/no-image.jpg"
                                                                                alt="image"
                                                                            />
                                                                        )}
                                                                        {/* <span className="live-dot"></span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="rated-customer-bx-inner review_author">
                                                                    <h3>
                                                                        {reviewReply.userId &&
                                                                            reviewReply.userId
                                                                                .firstName}{" "}
                                                                        {""}{" "}
                                                                        {reviewReply.userId &&
                                                                            reviewReply.userId.lastName}
                                                                    </h3>
                                                                    <h2>{reviewReply.reply}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            {this.state.showReplyAsSeller ===
                                                review._id && (
                                                    <form className="input_detail_form">
                                                        <div className="review_reply">
                                                            <div className="row align-items-center w-100">
                                                                <div className="col-auto">
                                                                    <div className="detail-owner-name-bx clearfix review_author align-items-start">
                                                                        <div className="detail-owner-img reply_img_b">
                                                                            {this.state.profilePicture ? (
                                                                                <img
                                                                                    src={General.showImageNew(
                                                                                        "profile",
                                                                                        this.state
                                                                                            .profilePicture
                                                                                    )}
                                                                                    alt={
                                                                                        this.state
                                                                                            .profilePicture
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src="/images/no-image.jpg"
                                                                                    alt="image"
                                                                                />
                                                                            )}

                                                                            {/* <span className="live-dot"></span> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <textarea
                                                                            className="form-control-line h-30px"
                                                                            rows="2"
                                                                            name="reply"
                                                                            value={this.state.reply}
                                                                            onChange={(e) =>
                                                                                this.setState({
                                                                                    reply: e.target.value,
                                                                                })
                                                                            }
                                                                        ></textarea>
                                                                        <span
                                                                            className={
                                                                                "review-error-profile review-error-" +
                                                                                review._id
                                                                            }
                                                                        ></span>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="flex-g">
                                                                            <div className="form-group">
                                                                                <button
                                                                                    type="submit"
                                                                                    onClick={(e) =>
                                                                                        this.handleReplySubmit(
                                                                                            e,
                                                                                            review._id
                                                                                        )
                                                                                    }
                                                                                    className="btnx btn-secondaryx"
                                                                                >
                                                                                    Reply
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>


                    <div className="product-all-detail-tab customer-review-bx mt-3 mt-sm-0 mobile_view">
                        <h2 className="padding-mobile">
                            {allReviews && allReviews.length} Reviews from  Customers
                        </h2>
                        <div className="d-flex rating_section">
                            <div className="star star-filter" onClick={() => this.filterWithRating(0)}>
                                <span>
                                    (All)
                                </span>
                            </div>
                            <div className="star star-filter col d-content" onClick={() => this.filterWithRating(5)}>
                                <span>
                                    (5)
                                </span>
                                <StarRatings
                                    rating={5}
                                    starRatedColor="#249ee4"
                                    starDimension="17px"
                                    starSpacing="1px"
                                    numberOfStars={5}
                                />
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">5 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                5
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                5
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(5)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 5)})
                                        </span>
                                    </div>
                                </div>


                            </div>
                            <div className="star star-filter col d-content" onClick={() => this.filterWithRating(4)}>
                                <span>
                                    (4)
                                </span>
                                <StarRatings
                                    rating={4}
                                    starRatedColor="#249ee4"
                                    starDimension="17px"
                                    starSpacing="1px"
                                    numberOfStars={5}
                                />
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">4 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                4
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                4
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(4)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 4)})
                                        </span>
                                    </div>
                                </div>



                            </div>

                            <div className="star star-filter col d-content" onClick={() => this.filterWithRating(3)}>
                                <span>
                                    (3)
                                </span>
                                <StarRatings
                                    rating={3}
                                    starRatedColor="#249ee4"
                                    starDimension="17px"
                                    starSpacing="1px"
                                    numberOfStars={5}
                                />
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">3 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                3
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                3
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(3)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 3)})
                                        </span>
                                    </div>
                                </div>


                            </div>
                            <div className="star star-filter col d-content" onClick={() => this.filterWithRating(2)}>
                                <span>
                                    (2)
                                </span>
                                <StarRatings
                                    rating={2}
                                    starRatedColor="#249ee4"
                                    starDimension="17px"
                                    starSpacing="1px"
                                    numberOfStars={5}
                                />
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">2 Stars</div>
                                    <div className="rating-view col">

                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                2
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                2
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(2)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 2)})
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="star star-filter col d-content" onClick={() => this.filterWithRating(1)}>
                                <span>
                                    (1)
                                </span>
                                <StarRatings
                                    rating={1}
                                    starRatedColor="#249ee4"
                                    starDimension="17px"
                                    starSpacing="1px"
                                    numberOfStars={5}
                                />
                                <div className="rating-row row align-items-center">
                                    <div className="star-txt">1 Stars</div>
                                    <div className="rating-view col">
                                        <LinearProgress
                                            variant="determinate"
                                            value={General.showRatingPercentage(
                                                allReviews,
                                                1
                                            )}
                                        />
                                    </div>
                                    <div className="rating-text col">
                                        <h4>
                                            {General.showRatingPercentage(
                                                allReviews,
                                                1
                                            )}
                                            %
                                        </h4>
                                    </div>
                                    <div className="star col" onClick={() => this.filterWithRating(1)}>
                                        <span>
                                            ({General.showRatingCount(allReviews, 1)})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="customer-review-profile_block bg-white col-xl-8 mb-4">
                            <div className="row pb-3">
                                <div className="col-md-3">
                                    <div className="view-more-review">
                                        View
                                        <b>
                                            {" "}
                                            {allReviewsList && allReviewsList.length}{" "}
                                        </b>{" "}
                                        reviews
                                    </div>{" "}
                                </div>
                                <div className="col-md-3">Share your reviews</div>
                                <div className="col-md-3">
                                    <div className="review-share">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fal"
                                            data-icon="share"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512"
                                            className="svg-inline--fa fa-share fa-w-18 fa-2x"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M564.907 196.35L388.91 12.366C364.216-13.45 320 3.746 320 40.016v88.154C154.548 130.155 0 160.103 0 331.19c0 94.98 55.84 150.231 89.13 174.571 24.233 17.722 58.021-4.992 49.68-34.51C100.937 336.887 165.575 321.972 320 320.16V408c0 36.239 44.19 53.494 68.91 27.65l175.998-184c14.79-15.47 14.79-39.83-.001-55.3zm-23.127 33.18l-176 184c-4.933 5.16-13.78 1.73-13.78-5.53V288c-171.396 0-295.313 9.707-243.98 191.7C72 453.36 32 405.59 32 331.19 32 171.18 194.886 160 352 160V40c0-7.262 8.851-10.69 13.78-5.53l176 184a7.978 7.978 0 0 1 0 11.06z"
                                                className=""
                                            ></path>
                                        </svg>
                                        Share
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="rated-customer-bx-inner">
                                        <div className="rated-star text-left">
                                            <span>Rated</span>
                                            <StarRatings
                                                rating={this.state.avgRating}
                                                starRatedColor="#249ee4"
                                                starDimension="20px"
                                                starSpacing="0"
                                                numberOfStars={5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CustomerReviewComponent;
