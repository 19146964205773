import Model from "Models/Model";

class LiveStreamingBid extends Model {
  dbSchema() {
    return {
      amount: "",
    };
  }

  validationRules() {
    return {
      amount: [
        { lib: "Custom", func: "NotEmpty", msg: "Amount is required" },
        { lib: "Validator", func: "isNumeric", msg: "Amount must be numeric" },
        {
          lib: "Validator",
          func: "isFloat",
          msg: "Price must be less than 100,000",
          option: { lt: 100000 },
        },
      ],
    };
  }
}

export default new LiveStreamingBid();
