import React, { Component } from "react";
import Product from "Models/Product";
import Order from "Models/Order";
import MyProfile from "Models/MyProfile";
import CheckoutModel from "Models/CheckoutModel";
import General from "Helpers/General";
import CheckoutOrderForm from "../Stripe/OrderCheckout";
import QB, { playCallAudio } from "Helpers/Quickblox";
import { connect } from "react-redux";
import {
  OpenMessageBox,
  MessageData,
  SetSelectedChannel,
  SetOutgoingCallState,
  SetQuickbloxSession,
  SetSelectedUser,
  SetContactList,
} from "../../Helpers/Actions";
import Modal from "react-modal";
var qs = require("qs");

// Define the BuyerProtectionModal using react-modal
const BuyerProtectionModal = ({ open, handleClose, generalSettingsData }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      contentLabel="Buyer Protection"
      className="modal-content protection_modal"
      overlayClassName="modal-overlay"
    >
      <button onClick={handleClose} className="shipCloseBtn">
        <i className="fa fa-times "></i>
      </button>
      <div className="modal-header">
        {/* <img src="/images//right-icon.png" /> */}
        <h2 id="buyer-protection-title" className="modal_title">Buyer Protection</h2>
        <span>
          <i className="fa-regular fa-circle-xmark" onClick={handleClose} />
        </span>
      </div>
      <div className="modal-body">
        <p className="" style={{ color: "#409b7f", textAlign: "center" }}><u>Learn how we calculate the Buyer Protection fee.</u></p>
        <div className="list_group_box">

          <div className="list_header">
            {/* <i className="fa fa-money-bill protectionModalIcon"></i> */}
            <h6 className="head_title">Refund policy</h6>
          </div>
          {generalSettingsData["miscellaneous"]
            ? generalSettingsData["miscellaneous"][0]["buyerProtection"]
            : ""}
        </div>
      </div>
    </Modal>
  );
};

class CheckoutComponent extends Component {
  constructor(props) {
    super(props);

    if (!Product.isLogin()) {
      Product.redirectTo("");
    }
    let session_id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).session_id;
    let session_resp = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).response;
    let session_slug = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).slug;
    // alert("session_resp "+ session_resp)
    window.scrollTo(0, 0);

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      id: "",
      name: "",
      firstName: "",
      lastName: "",
      postageDetail: {},
      productData: {},
      products: {},
      weekendData: {},
      productUserId: "",
      sellerEmail: "",
      buy_productId: "",
      showBuyNowButton: 1,
      paymentAmount: 0,
      productPriceCost: 0,
      allowBuyNow: false,
      buyerProtuctionCost: 0,
      session_id: session_id ? session_id : "",
      session_resp: session_resp ? session_resp : "",
      session_slug: session_slug ? session_slug : "",
      adminCost: 0,
      deliveryCharge: 0,
      productName: "",
      countries: [],
      regions: [],
      cities: [],
      transaction_type: "order",
      orderPaymentModel: false,
      orderNumber: 0,
      seller_admin_fees: 0,
      seller_protection_fee: 0,
      seller_total_amount: 0,
      delivery: 0,
      generalSettingsData: localStorage.getItem("generalSettingsData")
        ? JSON.parse(localStorage.getItem("generalSettingsData"))
        : {},
      isLoading: false,
      isModalOpen: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  componentDidMount = async () => {
    Product.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          this.setState({ name: response.firstName + " " + response.lastName });
          this.setState({ firstName: response.firstName });
          this.setState({ lastName: response.lastName });
          this.setState({ postageDetail: response.postageDetail });
          this.setState({ id: response._id });
        }
      },
      false
    );
    this.getProductById();
    this.getAllCountries();

    let stripe_code = this.state.session_id;
    let session_resp = this.state.session_resp;
    let session_slug = this.state.session_slug;
    if (stripe_code) {
      this.setState({ session_id: stripe_code });
      this.setState({ session_resp: session_resp });
      this.setState({ session_slug: session_slug });
      console.log("stripe_code", this.state.session_id);
      // self.setState({ isLoading: true });
      // const stripeRespo = await this.saveOrderDetails(stripe_code,session_resp);
      // console.log("stripeRespo success",stripeRespo)
      // if (stripeRespo.status === "success") {
      //   // Product.redirectTo("post-an-ad");
      //   console.log("stripeRespo success",stripeRespo)
      // }else{
      //   // this.setState({ isLoading: false });
      //   console.log("stripeRespo error",stripeRespo)
      // }
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params.productSlug !== prevProps.match.params.productSlug
    ) {
      console.log("check slug", prevProps.match.params.productSlug);
      this.getProductById();
    }
  };

  getProductById = async () => {
    let frontUrl = process.env.REACT_APP_FRONTEND_URL;
    console.log("slug", this.props.match.params.productSlug);

    let productId = this.props.match.params.productSlug;
    if (this.state.session_resp == "error") {
      productId = this.state.session_slug;
    }
    Product.getApiByParams(
      "APIS_GET_PRODUCT_BY_SLUG",
      productId,
      async (response, status) => {
        console.log("resp", response);
        if (response) {
          this.setState({ productData: response });
          this.setState({ products: response.products });
          this.setState({ productUserId: response.products.userId._id });
          this.setState({ sellerEmail: response.products.userId.email });
          this.setState({ buy_productId: response.products._id });
          this.setState({ weekendData: response.weekendDealData });
          this.setState({ delivery: response.products.delivery });
          this.setState({ seller_admin_fees: response.products.seller_admin_fees });
          this.setState({ seller_protection_fee: response.products.seller_protection_fee });
          console.log("check sellerid", this.state.productUserId);
          if (
            response.products.isWeekEndDeal &&
            response.weekendDealData.start
          ) {
            this.setState({
              paymentAmount: response.weekendDealData.weekendPrice,
            });
            this.setState({
              productPriceCost: response.weekendDealData.weekendPrice,
            });
          } else {
            this.setState({ paymentAmount: response.products.productPrice });
            this.setState({ productPriceCost: response.products.productPrice });
          }
          // if (response.products.thirdCategoryId != null) {
          //   this.setState({
          //     allowBuyNow: response.products.thirdCategoryId.allowBuyNow,
          //   });
          // } else 
          if (response.products.subCategoryId != null) {
            this.setState({
              allowBuyNow: response.products.subCategoryId.allowBuyNow,
            });
          }
          if (
            response.products.deliveryCost != null &&
            response.products.deliveryCost > 0
          ) {
            let totalProductCost =
              Number(this.state.paymentAmount) +
              Number(response.products.deliveryCost);
            this.setState({ deliveryCost: true });
            this.setState({
              deliveryCharge: Number(response.products.deliveryCost),
            });
            this.setState({ paymentAmount: totalProductCost });
          }

          if (response.products.buyerProtectionCharge) {
            let totalProductCost =
              Number(this.state.paymentAmount) +
              Number(response.products.buyerProtectionCharge);
            this.setState({ paymentAmount: totalProductCost });
            this.setState({
              buyerProtuctionCost: Number(
                response.products.buyerProtectionCharge
              ),
            });
          }
          if (
            this.state.generalSettingsData &&
            this.state.generalSettingsData?.miscellaneous
          ) {
            let adminCost = this.state.generalSettingsData.miscellaneous.map(
              (item) => {
                return item.adminServiceCharge;
              }
            );
            let totalProductCost =
              Number(this.state.paymentAmount) + Number(adminCost);
            this.setState({ paymentAmount: totalProductCost });
            this.setState({ adminCost: Number(adminCost) });
          }
          let finalTotalAmount = Number(this.state.paymentAmount - this.state.adminCost - response.products.buyerProtectionCharge - response.products.seller_protection_fee - response.products.seller_admin_fees).toFixed(2);
          this.setState({ seller_total_amount: finalTotalAmount });
          console.log("finalTotalAmount", finalTotalAmount);
          if (
            response.products.productMainImage &&
            response.products.productImages
          ) {
            response.products.productImages.sort(function (x, y) {
              return x === response.products.productMainImage
                ? -1
                : y === response.products.productMainImage
                  ? 1
                  : 0;
            });
          }
          this.getProductOrderStatus();
          console.log("check session id avail", this.state.session_id);
          console.log("session_resp", this.state.session_resp);
          if (this.state.session_resp == "success") {
            this.saveOrderDetails();
          } else if (this.state.session_resp == "error") {
            const currentUrl = window.location.href;
            const newUrl = currentUrl.split("?")[0];
            window.history.replaceState({ path: newUrl }, "", newUrl);
            this.state.products["isSold"] = false;
          }
        } else {
          // window.location.href = '/'
          //console.log("productId",productId)
          // Product.redirectTo(`product/${this.props.match.params.productSlug}`);
          this.props.history.goBack();
        }
      }
    );
  };

  getProductOrderStatus = () => {
    const buy_productId = this.state.buy_productId;
    const buyerId = this.state.id;
    Order.postApi(
      "API_GET_ORDERED_PRODUCT_STATUS",
      { productId: buy_productId, buyerId: buyerId },
      async (response, status) => {
        // console.log(response,'response123')
        if (response) {
          if (response.status == "Paid") {
            this.setState({ showBuyNowButton: 0 });
            this.setState({ orderNumber: response.order_number });
          } else {
            this.setState({ showBuyNowButton: 1 });
          }
        } else {
          this.setState({ showBuyNowButton: 1 });
        }
        // this.getProductById();
      }
    );
  };

  getAllCountries = () => {
    MyProfile.postApiByParams(
      "APIS_GET_COUNTRIES",
      {},
      async (response, status) => {
        if (status === "success") {
          this.setState({
            countries: response.data,
          });
        }

        this.getAllRegions();
        this.getAllCities();
      },
      true
    );
  };

  getAllRegions = () => {
    let country_id = this.state.postageDetail?.country_id
      ? this.state.postageDetail.country_id
      : "";
    if (country_id != undefined && country_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_STATE",
        { country_id: country_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              regions: response.data,
            });
          }
        },
        true
      );
    }
  };

  getAllCities = () => {
    let state_id = this.state.postageDetail?.region_id
      ? this.state.postageDetail.region_id
      : "";
    if (state_id != undefined && state_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_CITIES",
        { state_id: state_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              cities: response.data,
            });
          }
        },
        true
      );
    }
  };

  handelCountryDetail = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail, () => {
      this.getAllRegions();
    });
  };

  handelRegionDetail = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail, () => {
      this.getAllCities();
    });
  };

  // handlePostageAddress = (event) => {
  //   const { postageDetail } = this.state;
  //   postageDetail[event.target.name] = event.target.value;
  //   this.setState(postageDetail);
  // };

  /* handlePostcodeValidation = (event) => {
  let postcode = event.target.value;
  postcode = postcode.replace(/\s/g, "");
  var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
  alert(regex.test(postcode))
  return regex.test(postcode);
}; */

  createOrderProcess = async (products) => {
    console.log("system12233-->", this.props.match.params.productSlug)
    let slug = this.props.match.params.productSlug;
    let frontUrl = process.env.REACT_APP_FRONTEND_URL;
    // Save Order
    Order.postApi(
      "API_CREATE_ORDER",
      {
        buyer_id: this.state.id,
        seller_id: this.state.productUserId,
        product_id: this.state.buy_productId,
        amount: this.state.productPriceCost,
        buyer_protuction_cost: this.state.buyerProtuctionCost,
        delivery_charge: this.state.deliveryCharge,
        admin_cost: this.state.adminCost,
        total_amount: this.state.paymentAmount,
        seller_admin_fees: this.state.seller_admin_fees,
        seller_protection_fee: this.state.seller_protection_fee,
        seller_total_amount: Number(this.state.seller_total_amount),
        shipping_address: this.state.postageDetail,
        delivery: this.state.delivery,
        slug: slug,
      },
      async (response, status) => {
        console.log("response", response.order_number);
        if (status == "success") {
          const { postageDetail, delivery } = this.state;
          let frontUrl = process.env.REACT_APP_FRONTEND_URL;
          let pay_process = true;

          if (delivery === 2) {
            if (
              (await CheckoutModel.checkValidation(
                await CheckoutModel.validationRules(),
                postageDetail
              )) === true
            ) {
              pay_process = true;
              // this.setState({orderPaymentModel : true});
            }
          } else {
            pay_process = true;
            // this.setState({orderPaymentModel : true});
          }
          if (pay_process === true) {
            Order.postApi(
              "APIS_STRIPE_CHECKOUT",
              {
                amount: this.state.paymentAmount,
                buyer_protuction_cost: this.state.buyerProtuctionCost,
                delivery_charge: this.state.deliveryCharge,
                admin_cost: this.state.adminCost,
                total_amount: this.state.paymentAmount,
                seller_admin_fees: this.state.seller_admin_fees,
                seller_protection_fee: this.state.seller_protection_fee,
                seller_total_amount: Number(this.state.seller_total_amount),
                buyer_id: this.state.id,
                quantity: 1,
                cancel_url: `${frontUrl}/checkout/${slug}`,
                success_url: `${frontUrl}/thankyou/${response._id}`,
                productName: products.productName,
                order_id: response._id,
                slug: slug,
              },
              async (response, status) => {
                if (status == "success" && response) {
                  window.location.href = `${response.url}`;

                } else {
                  this.state.products["isSold"] = false;
                }
              }
            );
          } else {
            return;
          }
        } else {
        }
      }
    );
  };
  // saveOrderDetails = async () => {
  //   Order.postApi(
  //     "API_CREATE_ORDER",
  //     {
  //       buyer_id: this.state.id,
  //       seller_id:  this.state.productUserId,
  //       product_id: this.state.buy_productId,
  //       amount: this.state.productPriceCost,
  //       session_id: this.state.session_id,
  //       buyer_protuction_cost: this.state.buyerProtuctionCost,
  //       delivery_charge:this.state.deliveryCharge,
  //       admin_cost : this.state.adminCost,
  //       total_amount:this.state.paymentAmount,
  //       shipping_address: this.state.postageDetail,
  //       delivery:this.state.delivery,
  //       status:this.state.session_resp
  //     },
  //     async (response, status) => {
  //       console.log("response",response.order_number)
  //       if(status == "success"){
  //         // paymentHandler(status,response);
  //         // const paymentHandler = (status,response) => {

  //           // this.setState({orderPaymentModel:false});
  //             var seller_email = this.state.sellerEmail;
  //             let orderNumber = response._id;

  //             this.state.products["isSold"] = true;
  //             this.setState({ products: this.state.products });

  //             var searchParams = {login: seller_email};
  //             let msg = 'Your product '+this.state.products.productName+' has been purchased.';
  //             QB.users.get(searchParams, function(error, user) {
  //                 if(!error){
  //                     let params = {
  //                       type: 3,
  //                       occupants_ids: [user.id],
  //                     };

  //                     QB.chat.dialog.create(params, (error, dialog) => {
  //                       if (!error) {
  //                         let message = {
  //                             type: "chat",
  //                             body: msg,
  //                             extension: {
  //                                 save_to_history: 1,
  //                                 dialog_id: dialog._id,
  //                             },
  //                             markable: 1,
  //                         };
  //                         QB.chat.send(user.id, message);
  //                       } else {
  //                         console.log("Dialog Create Error", error);
  //                       }
  //                     });
  //                 }
  //             });
  //             /* Product.redirectTo(
  //               `thankyou/${orderNumber}`
  //             ); */
  //             setTimeout(function(){
  //               Product.redirectTo(
  //                 `thankyou/${orderNumber}`
  //               );
  //             },3000);
  //             /* setTimeout(function(){
  //               Product.redirectTo(
  //                 `my-profile/activity`
  //               );
  //             },3000); */

  //       }else if (status === "transaction_error"){
  //             Product.toastAlert("error", "Transaction failed!");
  //       }

  //     },
  //     true
  //   );
  // }

  setClassActive = async () => {
    if (localStorage.getItem("AuthIdentity")) {
      QB.users.get(
        { login: this.state.products.userId.email },
        (error, user) => {
          if (!error) {
            try {
              QB.chat.roster.add(user.id, function () { });
            } catch (e) {
              if (e.name === "ChatNotConnectedError") {
                // not connected to chat
              }
            }
            const contacts = JSON.parse(
              localStorage.getItem("QB_CONTACT_LIST")
            );
            let allContacts = [];

            const restContacts = contacts.filter(
              (user1) => user1.user.id !== user.id
            );

            allContacts = [{ user }, ...restContacts];
            localStorage.setItem(
              "QB_CONTACT_LIST",
              JSON.stringify(allContacts)
            );
            this.props.SetContactList(allContacts);
            this.props.SetSelectedUser([{ user }]);

            this.props.OpenMessageBox(true);
            localStorage.setItem("isOpenMessageBox", JSON.stringify(true));
            localStorage.setItem("isClosedMessageBox", JSON.stringify(false));
          }
        }
      );
    }
  };

  // handleOpenModal = () => {
  //   this.setState({ isModalOpen: true });
  // };

  // handleCloseModal = () => {
  //   this.setState({ isModalOpen: false });
  // };

  render() {
    const {
      products,
      weekendData,
      paymentAmount,
      productPriceCost,
      deliveryCharge,
      buyerProtuctionCost,
      postageDetail,
      countries,
      regions,
      cities,
      isLoading,
      isModalOpen
    } = this.state;

    const onLoading = (value) => {
      this.setState({ isLoading: value });
    };

    const onClose = () => {
      this.setState({ orderPaymentModel: false });
    };

    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        {products && (
          <section className="repeat-section product-detail-main-bx bg-light-dark onmobilp_topnon  mobiledisplay_oneline full-page-height">
            <div className="container">
              {console.log("setting", this.state.generalSettingsData[
                "miscellaneous"
              ][0]["buyerProtection"])}
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8">
                  <div className="checkout_card mb-3">
                    <div className="checkout_inner_details">
                      <div className="seller_name">
                        <p>
                          Seller{" "}
                          <span>
                            : {products.userId && products.userId.firstName}{" "}
                            {products.userId && products.userId.lastName}
                          </span>
                        </p>
                      </div>
                      <div className="user_details">
                        <div className="left_user_details">
                          <div className="img_user">
                            {products.productMainImage ? (
                              <img
                                src={General.showImage(
                                  "products/resize-180-180",
                                  products.productMainImage
                                )}
                                alt=""
                              />
                            ) : (
                              <img src="/images/no-image.jpg" alt="" />
                            )}
                          </div>
                          <div className="product_name">
                            <h5>{products?.productName}</h5>
                            {/*  <div className="other_details">
                               <span>{products?.location}</span>
                                </div>  */}
                          </div>
                        </div>
                        <div className="right_prise">
                          {products.isWeekEndDeal && weekendData.start ? (
                            <div className="d-flex align-items-center">
                              <p className="font-size-16">
                                <del>
                                  {General.showPriceDecimal(products.productPrice)}
                                </del>
                              </p>
                              <span className="discountt">
                                -{weekendData?.discountPercent}%
                              </span>
                              <p className="mt-0 font-size-16">
                                {General.showPriceDecimal(weekendData?.weekendPrice)}
                              </p>
                            </div>
                          ) : (
                            <p className="font-size-16">{General.showPriceDecimal(products.productPrice)}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkout_card_two mb-3">
                    <div className="delivery_option_block">
                      <span>Delivery Option</span>
                      <h5>
                        {this.state.delivery === 1
                          ? "Local pick-up from seller"
                          : "Ship to home"}
                      </h5>
                      {this.state.delivery === 2 && (
                        <div className="from_order">
                          From {General.showPriceDecimal(deliveryCharge)}
                        </div>
                      )}
                      <div className="message_to_seller ">
                        <p>
                          {" "}
                          <a href={void 0} onClick={this.setClassActive}>
                            {" "}
                            <img src="/images/act02.png" alt="icon" /> Message
                          </a>{" "}
                          to seller
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4">
                  <div className="checkout_card">
                    <div className="right_inner_details">
                      <h4>Order Summary</h4>
                      <ul>
                        <li>
                          <span>Order</span>
                          <span>
                            {" "}
                            {General.showPriceDecimal(productPriceCost)}
                          </span>
                        </li>
                        <li>
                          <span>
                            Buyer Protection fee{" "}
                            <a href={void 0} onClick={this.handleOpenModal}>
                              <i className="fas fa-info"></i>{" "}
                            </a>
                          </span>
                          <BuyerProtectionModal
                            open={isModalOpen}
                            handleClose={this.handleCloseModal}
                            generalSettingsData={this.state.generalSettingsData}
                          />
                          <span>
                            {General.showPriceDecimal(
                              buyerProtuctionCost + this.state.adminCost
                            )}
                          </span>
                        </li>
                        {this.state.delivery === 2 && (
                          <li>
                            <span>Shipping</span>
                            <span>
                              {General.showPriceDecimal(deliveryCharge)}
                            </span>
                          </li>
                        )}
                        {this.state.delivery === 1 && (
                          <li>
                            <span>Local collection from seller</span>
                            <span>{General.showPriceDecimal(0)}</span>
                          </li>
                        )}
                        <li>
                          <span>Total to Pay</span>
                          <span>{General.showPriceDecimal(paymentAmount)}</span>
                        </li>
                      </ul>
                      {this.state.id !== this.state.productUserId &&
                        this.state.allowBuyNow === true &&
                        products.isSold === false && (
                          <button
                            className="btn btn-sm"
                            onClick={() => this.createOrderProcess(products)}
                          >
                            Pay Now
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* <CheckoutOrderForm parentState={this.state} paymentHandler={paymentHandler} onLoading={onLoading} onClose={onClose}/> */}
      </React.Fragment>
    );
  }
}

export default connect(null, {
  OpenMessageBox,
  MessageData,
  SetSelectedChannel,
  SetOutgoingCallState,
  SetQuickbloxSession,
  SetSelectedUser,
  SetContactList,
})(CheckoutComponent);
