import React, { Component } from "react";
import Product from "Models/Product";
import General from "Helpers/General";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { Rating } from 'react-simple-star-rating';

class AdsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allAdsProducts: this.props.products,
      showHideListData: false,
      showHideGridData: true,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      nothingD: props.nothingD,
      openProductFilter: false,
      userLoginId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
    };
    this.hideComponent = this.hideComponent.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleOtherOption = this.handleOtherOption.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.handlePriceData = this.handlePriceData.bind(this);
  }

  static getDerivedStateFromProps = (props, state) => {
    const update = {};
    if (props.products !== state.allAdsProducts) {
      update.allAdsProducts = props.products;
    }
    if (props.nothingD !== state.nothingD) {
      update.nothingD = props.nothingD;
    }

    return update;
  };

  hideComponent(name) {
    switch (name) {
      case "showHideListData":
        this.setState({ showHideListData: !this.state.showHideListData });
        this.setState({ showHideGridData: false });
        break;
      case "showHideGridData":
        this.setState({ showHideGridData: !this.state.showHideGridData });
        this.setState({ showHideListData: false });
        break;
      default:
        this.setState({ showHideListData: !this.state.showHideListData });
        break;
    }
  }

  componentDidMount = () => {
    var self = this;
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.addEventListener('click', this.handleOverlayClick);
    }
  };

  handleSorting(e) {
    this.handleOverlayClick();
    this.props.filterSortingHandler(e);
  }

  handleOtherOption(e) {
    this.handleOverlayClick();
    this.props.otherOptionHandler(e);
  }

  handlePriceData() {
    this.handleOverlayClick();
    this.props.getAllProducts();
  }

  handleCategory(id = "") {
    this.handleOverlayClick();
    this.props.categoryFilter(id);
  }

  handleOverlayClick() {
    document.body.classList.remove('filter-open');
    this.setState({ openProductFilter: false });
  }

  componentWillUnmount() {
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.removeEventListener('click', this.handleOverlayClick);
    }
  }

  render() {
    const { showHideListData, showHideGridData, allAdsProducts } = this.state;
    const self = this;
    return (
      <React.Fragment>
        <div
          className={"tab-pane allcom_rodtab tabpremium_target " + (this.props.showSection ? 'active' : '')}
          id="Jcomp_allprod"
          role="tabpanel"
        >
          <div className="row">
            <div
              className="col-lg-3 productlist_lefftt paddingrigt_none_Cfilter height-auto"
              id="side-bar-fixed"
              
            >
              <div className="allpeod_adstitle">
                {/* <h4 className="font-size-16">All Ads</h4> */}
              </div>
              <div
                className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                  }`}
              >
                <div className="product-listing-sideBar  mobile-listing-bx">
                  <div className="product-listing-sideBar desktop_fillter" id="side-bar-fixed">
                    <div id="filter-closer">
                      <img
                        src="/images/cancel.svg"
                        alt="icon"
                        onClick={() =>
                          this.setState({ openProductFilter: false })
                        }
                      />
                    </div>
                    <div className="product-listing-sideBar-colum sideBar-category">
                      <h2 className="toggle-category">
                        Category
                        <i className="fas fa-chevron-down"></i>
                      </h2>
                      <ul className="toggle-bx">
                        <li
                          className={`${this.props.selectedCategory === "" ? "active" : ""
                            }`}
                        >
                          <a href="#!" onClick={() => this.props.categoryFilter()}>
                            All
                          </a>
                        </li>
                        {this.props.categories &&
                          this.props.categories.map((category) => (
                            <li
                              key={category._id}
                              className={`${category._id === this.props.selectedCategory
                                ? "active"
                                : ""
                                }`}
                            >
                              <a
                                href="#!"
                                onClick={() =>
                                  this.props.categoryFilter(category._id)
                                }
                              >
                                {category.categoryName}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="product-listing-sideBar-colum sideBar-category">
                      <h2>Price</h2>
                      <div className="row">
                        <div className="col-5 pr-1">
                          <div className="form-group">
                            <input
                              type="text"
                              name="min_price"
                              className="form-control-line"
                              placeholder="Min"
                              onChange={(e) => this.props.handlePrice(e)}
                            />
                          </div>
                        </div>
                        <div className="col-5 pr-1">
                          <div className="form-group">
                            <input
                              type="text"
                              name="max_price"
                              className="form-control-line"
                              placeholder="Max"
                              onChange={(e) => this.props.handlePrice(e)}
                            />
                          </div>
                        </div>
                        <div className="col-2 px-0">
                          <button className="go-btn" onClick={this.handlePriceData}>GO</button>
                        </div>
                      </div>
                    </div>
                    <div className="product-listing-sideBar-colum other-option otheropt_newcls">
                      <h2>Other Options</h2>
                      {this.props.showOtherFilter &&
                        <div className="otheroption_cheperent">
                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="upcomingLiveAuction"
                              id="upcoming-auction"
                              value="yes"
                              onClick={this.handleOtherOption}
                            />
                            <label htmlFor="upcoming-auction">
                              Upcoming Live Auction
                            </label>
                          </div>
                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="liveAuction"
                              id="live-auction"
                              value="yes"
                              onClick={this.handleOtherOption}
                            />
                            <label htmlFor="live-auction">
                              Live Auction
                            </label>
                          </div>
                        </div>}
                      {!this.props.showOtherFilter &&
                        <div className="otheroption_cheperent">
                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="AvailableBuyNow"
                              id="available-buy-now"
                              value="yes"
                              onClick={this.handleOtherOption}
                            />
                            <label htmlFor="available-buy-now">
                              Available for delivery
                            </label>
                          </div>
                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="AvailablePickUp"
                              id="available-pickup"
                              value="yes"
                              onClick={this.handleOtherOption}
                            />
                            <label htmlFor="available-pickup">
                              Available for pick up
                            </label>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="product-listing-sideBar mobile_fillter mobile_fillter_bx mobile-padding-0" id="side-bar-fixed">
                    <div className="fillter_preant w-100">
                      <div className="sidebarleft_newinner test2">
                        <div id="filter-closer">
                          <img
                            src="/images/cancel.svg"
                            alt="icon"
                            onClick={() => {
                              this.setState({ openProductFilter: false });
                              document.body.classList.remove('filter-open');
                            }
                            }
                          />
                        </div>
                        <div className="filter-clear">
                          <button
                            className="btn btn-sm"
                            onClick={() =>
                              window.location.reload()
                            }
                          >
                            Clear Filter
                          </button>
                        </div>
                        {this.state.filterKeyWord === "category" &&
                          <div className="product-listing-sideBar-colum sideBar-category">
                            <h2 className="toggle-category">
                              Category 
                            </h2>
                            <ul className="toggle-bx all-product-listin-box innerfilt_topcat border-top-0">
                              <li
                                className={`${this.props.selectedCategory === "" ? "active" : ""
                                  }`}
                              >
                                <a href="#!" onClick={() => this.handleCategory()}>
                                  All
                                </a>
                              </li>
                              {this.props.categories &&
                                this.props.categories.map((category) => (
                                  <li
                                    key={category._id}
                                    className={`${category._id === this.props.selectedCategory
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <a
                                      href="#!"
                                      onClick={() =>
                                        this.handleCategory(category._id)
                                      }
                                    >
                                      {category.categoryName}
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        }
                        {this.state.filterKeyWord === "price" &&
                          <div className="product-listing-sideBar-colum sideBar-category">
                            <h2>Price</h2>
                            <div className="row">
                              <div className="col-5 pr-1">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="min_price"
                                    className="form-control-line"
                                    placeholder="Min"
                                    onChange={(e) => this.props.handlePrice(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-5 pr-1">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="max_price"
                                    className="form-control-line"
                                    placeholder="Max"
                                    onChange={(e) => this.props.handlePrice(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-2 px-0">
                                <button className="go-btn" onClick={this.handlePriceData}>GO</button>
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.filterKeyWord === "other" &&
                          <div className="product-listing-sideBar-colum sideBar-category">
                            <h2>Other Options</h2>
                            {this.props.showOtherFilter &&
                              <div className="otheroption_cheperent">
                                <div className="check-box">
                                  <input
                                    type="checkbox"
                                    name="upcomingLiveAuction"
                                    id="upcoming-auction-2"
                                    value="yes"
                                    onClick={this.handleOtherOption}
                                  />
                                  <label htmlFor="upcoming-auction">
                                    Upcoming Live Auction
                                  </label>
                                </div>
                                <div className="check-box">
                                  <input
                                    type="checkbox"
                                    name="liveAuction"
                                    id="live-auction-2"
                                    value="yes"
                                    onClick={this.handleOtherOption}
                                  />
                                  <label htmlFor="live-auction">
                                    Live Auction
                                  </label>
                                </div>
                              </div>}
                            {!this.props.showOtherFilter &&
                              <div className="otheroption_cheperent">
                                <div className="check-box">
                                  <input
                                    type="checkbox"
                                    name="AvailableBuyNow"
                                    id="available-buy-now-2"
                                    value="yes"
                                    onClick={this.handleOtherOption}
                                  />
                                  <label htmlFor="available-buy-now">
                                    Available for delivery
                                  </label>
                                </div>
                                <div className="check-box">
                                  <input
                                    type="checkbox"
                                    name="AvailablePickUp"
                                    id="available-pickup-2"
                                    value="yes"
                                    onClick={this.handleOtherOption}
                                  />
                                  <label htmlFor="available-pickup">
                                    Available for pick up
                                  </label>
                                </div>
                              </div>
                            }
                          </div>
                        }
                        {this.state.filterKeyWord === "sort" && <div className="form-group-icon-bx">
                          <select
                            className="form-control-line"
                            onChange={this.handleSorting}
                          >
                            <option value="">Most Recent First</option>
                            <option value="LowToHigh">Price: Low to High</option>
                            <option value="HighToLow">Price: High to Low</option>
                            <option value="RatingLowToHigh">Rating: Low to High</option>
                            <option value="RatingHighToLow">Rating: High to Low</option>
                          </select>
                          <span className="form-group-icon">
                            <img src="/images/down-arrow-icon.png" alt="icon" />
                          </span>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 subtab_secondmain productlist_righhtt allprodlist_adverd">
              {/* <span className="allprod_pageonlyy">All Ads </span> */}
              <br></br>
              <div className="fillter_btn_box px-2">
                <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx fillter_btn_track"
                  onClick={() => {
                    this.setState({ openProductFilter: true, filterKeyWord: "category" })
                    document.body.classList.add('filter-open');
                  }}
                >
                  <div className="fillter_trackMobile">
                    <span>Category</span>
                    <span className="dropdown_arrow">
                      <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx fillter_btn_track"
                  onClick={() => {
                    this.setState({ openProductFilter: true, filterKeyWord: "price" })
                    document.body.classList.add('filter-open');
                  }}
                >
                  <div className="fillter_trackMobile">
                    <span>Price</span>
                    <span className="dropdown_arrow">
                      <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx fillter_btn_track"
                  onClick={() => {
                    this.setState({ openProductFilter: true, filterKeyWord: "sort" })
                    document.body.classList.add('filter-open');
                  }}
                >
                  <div className="fillter_trackMobile">
                    <span>Sort</span>
                    <span className="dropdown_arrow">
                      <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx fillter_btn_track"
                  onClick={() => {
                    this.setState({ openProductFilter: true, filterKeyWord: "other" })
                    document.body.classList.add('filter-open');
                  }}
                >
                  <div className="fillter_trackMobile">
                    <span>Other Options</span>
                    <span className="dropdown_arrow">
                      <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 d-none d-md-block">
                <div className="new_placetab_icon2">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={this.handleSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                      <option value="RatingLowToHigh">Rating: Low to High</option>
                      <option value="RatingHighToLow">Rating: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                  <div className="showing-result">
                  </div>
                </div>
              </div>
              <div className="Jcompny_allprod">
                {showHideListData && (
                  <div className="Jtaballp_content atlistingside_new">
                    <div className="row">
                      {allAdsProducts && allAdsProducts.length > 0 ? (
                        <>
                          {allAdsProducts.map(function (row) {
                            return (

                              <div
                                className="col-lg-12 listside_newperent"
                                key={"allAdsProducts" + row._id}
                              >
                                <div className="product-listing-product">
                                  <div className="row">
                                    <div className="col-3">
                                      <div className="product-img-bx">
                                        {self.props.showOtherFilter ? <Link
                                          to={Product.isLogin() ? {
                                            pathname: "/livestreamingProduct/" +
                                              row?.livestreamingId,
                                            query: {
                                              productId: row.productSlug,
                                            },
                                          } : "/upcomingLiveStreamingProduct/" +
                                          row.productSlug +
                                          "/" +
                                          row?.livestreamingId
                                          }
                                        >
                                          {row.adType && row.adType === "Featured" && (
                                            <div className="specialtags">
                                              <span
                                                className={
                                                  (row.adType === "Featured"
                                                    ? "black"
                                                    : "green") + "_special"
                                                }
                                              >
                                                {row.adType}{" "}
                                              </span>
                                            </div>
                                          )}
                                          {moment(row?.startTime).diff(
                                            moment(),
                                            "seconds"
                                          ) <= 0 ? (<span className="live-tag">
                                            <i className="fas fa-circle"></i> Live
                                          </span>) : ""}
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-180-180",
                                                row.productMainImage
                                              )}
                                              alt={row.productName}
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          <div className="totalimg_sectab">
                                            <Link
                                              to={Product.isLogin() ? {
                                                pathname: "/livestreamingProduct/" +
                                                  row?.livestreamingId,
                                                query: {
                                                  productId: row.productSlug,
                                                },
                                              } : "/upcomingLiveStreamingProduct/" +
                                              row.productSlug +
                                              "/" +
                                              row?.livestreamingId
                                              }
                                              className="totlimg_qt"
                                            >
                                              {row.productImages
                                                ? row.productImages.length
                                                : 0}{" "}
                                              <i className="fas fa-camera"></i>
                                            </Link>
                                          </div>
                                        </Link> : <Link
                                          to={"/product/" + row.productSlug}
                                        >
                                          {row.adType && row.adType === "Featured" && (
                                            <div className="specialtags">
                                              <span
                                                className={
                                                  (row.adType === "Featured"
                                                    ? "black"
                                                    : "green") + "_special"
                                                }
                                              >
                                                {row.adType}{" "}
                                              </span>
                                            </div>
                                          )}
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-180-180",
                                                row.productMainImage
                                              )}
                                              alt={row.productName}
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          <div className="totalimg_sectab">
                                            <Link
                                              to={`/product/${row.productSlug}`}
                                              className="totlimg_qt"
                                            >
                                              {row.productImages
                                                ? row.productImages.length
                                                : 0}{" "}
                                              <i className="fas fa-camera"></i>
                                            </Link>
                                          </div>
                                        </Link>}

                                      </div>
                                    </div>
                                    <div className="col-9">
                                      <div className="product-outer-bx productBig-outer-bx produc-grid-product">
                                        <div className="product-name-bx content_gobottom_new">
                                          <div className="displayInlineTime">
                                            <Moment fromNow>{row.time}</Moment>
                                            {self.props.showOtherFilter && <>
                                              {moment(row?.startTime).diff(
                                                moment(),
                                                "seconds"
                                              ) > 0 ? (
                                                <Countdown
                                                  date={row?.startTime}
                                                  zeroPadTime={2}
                                                  renderer={({ days, hours, minutes, seconds }) => (
                                                    <time>
                                                      {days  +
                                                        " days" +
                                                        " " +
                                                        hours +
                                                        ":" +
                                                        minutes +
                                                        ":" +
                                                        seconds}{" "}
                                                      to go
                                                    </time>
                                                  )}
                                                />) : ""}
                                            </>}
                                          </div>
                                          {self.props.showOtherFilter ? <Link
                                            to={Product.isLogin() ? {
                                              pathname: "/livestreamingProduct/" +
                                                row?.livestreamingId,
                                              query: {
                                                productId: row.productSlug,
                                              },
                                            } : "/upcomingLiveStreamingProduct/" +
                                            row.productSlug +
                                            "/" +
                                            row?.livestreamingId
                                            }
                                          ><h4>{row.productName}</h4></Link> : <Link
                                            to={"/product/" + row.productSlug}
                                          >
                                            <h4>{row.productName}</h4>
                                          </Link>}

                                          <h5>{row.location}</h5>
                                          <h6>
                                            <span>
                                              {
                                                self.props.showOtherFilter ? General.showPriceDecimal(
                                                  row.starting_price
                                                ) : General.showPriceDecimal(
                                                  row.productPrice
                                                )
                                              }
                                            </span>
                                            {/* <span>
                                              {General.showPriceDecimal(
                                                row.productPrice
                                              )}
                                            </span> */}
                                          </h6>
                                          <p>{row.productDescription}</p>
                                          {/* <button className="add-to-wishlist">
                                            <i className="far fa-heart"></i>
                                          </button> */}
                                          {/* <div className="usednew_button">
                                            <a href="#" className="btnnew_used">
                                              New
                                            </a>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {this.state.nothingD ? (<div className="col-lg-12">
                            <div className="alert alert-danger ">
                              <strong>Info!</strong> Nothing here.
                            </div></div>
                          ) : (
                            <>
                              {this.state.productSkeleton.map(function (
                                rowsskeleton
                              ) {
                                return (
                                  <ContentLoader
                                    viewBox="0 0 1380 300"
                                    height={340}
                                    width={1380}
                                  >
                                    <rect
                                      x="20"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="300"
                                      height="300"
                                    />
                                    <rect
                                      x="360"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="1000"
                                      height="300"
                                    />
                                  </ContentLoader>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {showHideGridData && (
                  <div className="Jtaballp_content02 auctionplist_main ">
                    <div className="col-sm-12 actionpage_list">
                      <div className="row low-margin">
                        {allAdsProducts && allAdsProducts.length > 0 ? (
                          <>
                            {allAdsProducts.map(function (row) {
                              return (
                                <div
                                  className="col-sm-3"
                                  key={"allAdsProducts" + row._id}
                                >
                                  <div className="product-outer-bx produc-grid-product">
                                    {!self.props.showOtherFilter ? (
                                      <>
                                        {row.livestreamingId ?
                                          <Link to={Product.isLogin() ? {
                                            pathname: "/livestreamingProduct/" +
                                              row?.livestreamingId,
                                            query: {
                                              productId: row.productSlug,
                                            },
                                          } : "/upcomingLiveStreamingProduct/" +
                                          row.productSlug +
                                          "/" +
                                          row?.livestreamingId
                                          }>
                                            <div className="product-img-bx ">
                                              {moment(row?.startTime).diff(
                                                moment(),
                                                "seconds"
                                              ) <= 0 ? (<span className="live-tag">
                                                <i className="fas fa-circle"></i> Live
                                              </span>) : ""}
                                              {row.productMainImage ? (
                                                <img
                                                  src={General.showImage(
                                                    "products/resize-180-180",
                                                    row.productMainImage
                                                  )}
                                                  alt={row.productName}
                                                />
                                              ) : (
                                                <img
                                                  src="/images/no-image.jpg"
                                                  alt="product"
                                                />
                                              )}
                                              {row.adType && (
                                                <div className="specialtags">
                                                  <span
                                                    className={
                                                      (row.adType === "Featured"
                                                        ? "black"
                                                        : "green") + "_special"
                                                    }
                                                  >
                                                    {row.adType}{" "}
                                                  </span>
                                                </div>
                                              )}
                                              <div className="totalimg_sectab">
                                                <Link
                                                  to={Product.isLogin() ? {
                                                    pathname: "/livestreamingProduct/" +
                                                      row?.livestreamingId,
                                                    query: {
                                                      productId: row.productSlug,
                                                    },
                                                  } : "/upcomingLiveStreamingProduct/" +
                                                  row.productSlug +
                                                  "/" +
                                                  row?.livestreamingId
                                                  }
                                                  className="totlimg_qt "
                                                >
                                                  {row.productImages
                                                    ? row.productImages.length
                                                    : 0}{" "}
                                                  <i className="fas fa-camera"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="product-name-bx">
                                              {moment(row?.startTime).diff(
                                                moment(),
                                                "seconds"
                                              ) > 0 ? (
                                                <Countdown
                                                  date={row?.startTime}
                                                  zeroPadTime={2}
                                                  renderer={({ days, hours, minutes, seconds }) => (
                                                    <time>
                                                      {days  +
                                                        " days" +
                                                        " " +
                                                        hours +
                                                        ":" +
                                                        minutes +
                                                        ":" +
                                                        seconds}{" "}
                                                      to go
                                                    </time>
                                                  )}
                                                />) : ""}
                                              <h6>
                                                <span>
                                                  {
                                                    self.props.showOtherFilter ? General.showPriceDecimal(
                                                      row.starting_price
                                                    ) : General.showPriceDecimal(
                                                      row.productPrice
                                                    )
                                                  }
                                                </span>
                                              </h6>
                                              {/* <Rating
                                                fillColor="#2f9ee4"
                                                className="rating-product"
                                                initialValue={row?.rating}
                                                readonly={true}
                                                size={15}
                                              /> */}
                                              <h4>{row.productName}</h4>
                                              <h3>{row.location}</h3>
                                            </div>
                                          </Link> : <Link to={"/product/" + row.productSlug}>
                                            <div className="product-img-bx">
                                              {row.productMainImage ? (
                                                <img
                                                  src={General.showImage(
                                                    "products/resize-180-180",
                                                    row.productMainImage
                                                  )}
                                                  alt={row.productName}
                                                />
                                              ) : (
                                                <img
                                                  src="/images/no-image.jpg"
                                                  alt="product"
                                                />
                                              )}
                                              {row.adType && (
                                                <div className="specialtags">
                                                  <span
                                                    className={
                                                      (row.adType === "Featured"
                                                        ? "black"
                                                        : "green") + "_special"
                                                    }
                                                  >
                                                    {row.adType}{" "}
                                                  </span>
                                                </div>
                                              )}
                                              <div className="totalimg_sectab">
                                                <Link
                                                  to={`/product/${row._id}`}
                                                  className="totlimg_qt totlimg_track "
                                                >
                                                  {row.productImages
                                                    ? row.productImages.length
                                                    : 0}{" "}
                                                  <i className="fas fa-camera"></i>
                                                </Link>
                                              </div>
                                            </div>
                                            <div className="product-name-bx">
                                              <h6>
                                                <span>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </span>
                                              </h6>
                                              {/* <Rating
                                                fillColor="#2f9ee4"
                                                className="rating-product"
                                                initialValue={row?.rating}
                                                readonly={true}
                                                size={15}
                                              /> */}
                                              <h4>{row.productName}</h4>
                                              <h3>{row.location}</h3>
                                            </div>
                                          </Link>}
                                      </>
                                    ) :
                                      <Link to={Product.isLogin() ? {
                                        pathname: "/livestreamingProduct/" +
                                          row?.livestreamingId,
                                        query: {
                                          productId: row.productSlug,
                                        },
                                      } : "/upcomingLiveStreamingProduct/" +
                                      row.productSlug +
                                      "/" +
                                      row?.livestreamingId
                                      }>
                                        <div className="product-img-bx">
                                          {moment(row?.startTime).diff(
                                            moment(),
                                            "seconds"
                                          ) <= 0 ? (<span className="live-tag">
                                            <i className="fas fa-circle"></i> Live
                                          </span>) : ""}
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-180-180",
                                                row.productMainImage
                                              )}
                                              alt={row.productName}
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          {row.adType && (
                                            <div className="specialtags">
                                              <span
                                                className={
                                                  (row.adType === "Featured"
                                                    ? "black"
                                                    : "green") + "_special"
                                                }
                                              >
                                                {row.adType}{" "}
                                              </span>
                                            </div>
                                          )}
                                          <div className="totalimg_sectab">
                                            <Link
                                              to={Product.isLogin() ? {
                                                pathname: "/livestreamingProduct/" +
                                                  row?.livestreamingId,
                                                query: {
                                                  productId: row.productSlug,
                                                },
                                              } : "/upcomingLiveStreamingProduct/" +
                                              row.productSlug +
                                              "/" +
                                              row?.livestreamingId
                                              }
                                              className="totlimg_qt"
                                            >
                                              {row.productImages
                                                ? row.productImages.length
                                                : 0}{" "}
                                              <i className="fas fa-camera"></i>
                                            </Link>
                                          </div>
                                        </div>
                                        <div className="product-name-bx">
                                          {self.props.showOtherFilter && <>
                                            {moment(row?.startTime).diff(
                                              moment(),
                                              "seconds"
                                            ) > 0 ? (
                                              <Countdown
                                                date={row?.startTime}
                                                zeroPadTime={2}
                                                renderer={({ days, hours, minutes, seconds }) => (
                                                  <time>
                                                    {days  +
                                                      " days" +
                                                      " " +
                                                      hours +
                                                      ":" +
                                                      minutes +
                                                      ":" +
                                                      seconds}{" "}
                                                    to go
                                                  </time>
                                                )}
                                              />) : ""}
                                          </>}
                                          <h6>
                                            <span>
                                              {
                                                self.props.showOtherFilter ? General.showPriceDecimal(
                                                  row.starting_price
                                                ) : General.showPriceDecimal(
                                                  row.productPrice
                                                )
                                              }
                                            </span>
                                          </h6>
                                          <Rating
                                            fillColor="#2f9ee4"
                                            className="rating-product"
                                            initialValue={row?.rating}
                                            readonly={true}
                                            size={15}
                                          />
                                          <h4>{row.productName}</h4>
                                          <h3>{row.location}</h3>
                                        </div>
                                      </Link>
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="alert alert-danger col-lg-12">
                            <strong>Info!</strong> Nothing here.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdsList;
